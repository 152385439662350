import React from "react";
import { Transition } from "react-transition-group";
import Button from "@mui/joy/Button";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import Typography from "@mui/joy/Typography";
import CloseIcon from "@mui/icons-material/Close";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import CheckIcon from '@mui/icons-material/Check';
import { primaryColor } from "Routes";
import useWindowSize from "useWindowSize";


const FadeInModal = ({children,childrenHtml, open, setOpen, width, top, noBackdrop,overflowY}) => {
  const innerWidth = useWindowSize();
    return (<Transition in={open} timeout={400}>
        {(state) => (
          <Modal
            keepMounted
            open={!["exited", "exiting"].includes(state)}
            onClose={() => setOpen(false)}
            slotProps={{
              backdrop: {
                sx: {
                
                  opacity: 0,
                  backdropFilter: "none",
                  transition: `opacity 400ms, backdrop-filter 400ms`,
                  ...{
                    entering: { opacity: noBackdrop? 0 : 1, backdropFilter: "blur(8px)", },
                    entered: { opacity: noBackdrop? 0 : 1, backdropFilter: "blur(8px)", },
                  }[state],
                },
              },
            }}
            sx={{
              scrollBehavior: "smooth",
              visibility: state === "exited" ? "hidden" : "visible",
              height: "auto",
              maxHeight: "auto",
              overflow: "scroll",
              overflowX: "hidden",
              marginTop: "0",
              top: top? top: "auto",
              paddingTop: "20px",
              width: width? width : "auto",
              scrollMarginTop: "30px",
             
            }}
          >
            <ModalDialog
              aria-labelledby="fade-modal-dialog-title"
              aria-describedby="fade-modal-dialog-description"
              sx={{
                height: "auto",
                maxHeight: "500vh",
                marginY: (innerWidth > 800? null : "0px"),
                maxHeight: "80vh",
                  overflowY: overflowY? overflowY : "scroll",
                  overflowX: "hidden",
                opacity: 0,
                scrollBehavior: "smooth",
                transition: `opacity 300ms height`,
                position: noBackdrop? null : "fixed",
                ...{
                  entering: { opacity: 1 },
                  entered: { opacity: 1 },
                }[state],
              }}
            >
              <CloseIcon
                sx={{ float: "right", ml: "auto", cursor: "Pointer" }}
                onClick={() => setOpen(false)}
              />
              {childrenHtml && 
    <div dangerouslySetInnerHTML={{__html: childrenHtml}}></div>}
              {children}
            </ModalDialog>
          </Modal>
        )}
      </Transition>)
}

export default FadeInModal;