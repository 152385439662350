import React from 'react';

function Expand(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const strokewidth = props.strokewidth || 1;
	const width = props.width || '100%';
	const height = props.height || '100%';

	return (
		<svg height={height} width={width} viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
	<g fill={secondaryfill} stroke={secondaryfill} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10" strokeWidth={strokewidth}>
		<path d="M5,21V10a5,5,0,0,1,5-5H21" fill="none" stroke={fill}/>
		<path d="M43,5H54a5,5,0,0,1,5,5V21" fill="none"/>
		<path d="M59,43V54a5,5,0,0,1-5,5H43" fill="none" stroke={fill}/>
		<path d="M21,59H10a5,5,0,0,1-5-5V43" fill="none"/>
	</g>
</svg>
	);
};

export default Expand;