import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {  useTheme } from '@mui/material';

const mock = [
  {
    feedback:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  },
  {
    feedback:
      'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  },
  {
    feedback:
      'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
  },
];

const Reviews = () => {
  const sliderOpts = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  const theme = useTheme();

  return (
    <Box maxWidth={700} width={1} margin={'0 auto'} sx={{textAlign: "center", color: "black", }}>
      <Typography
        variant={"h4"}
        align={'center'}
        sx={{ fontWeight: 700,marginBottom: 4, color: '#222' }}
      >
        Du willst Marktführer werden? Dabei helfen wir Dir gerne.
      </Typography>
     
      {/* <Slider {...sliderOpts}>
        {mock.map((item, i) => (
          <Box key={i} paddingX={4}>
            <Typography
              align={'center'}
              variant={'h6'}
              sx={{ color: 'common.black', fontStyle: 'italic' }}
            >
              {item.feedback}
            </Typography>
          </Box>
        ))}
      </Slider> */}
      {/* <Form/> */}
    
      {/* <InlineWidget
                      styles={{
                        height: "700px",
                        width: "auto",
                        marginTop: "100px"
                      }}
                      url="https://calendly.com/yellovation/potentialanalyse"
                      pageSettings={{
                        backgroundColor: "ffb547",
                        hideEventTypeDetails: true,
                        hideLandingPageDetails: true,
                        primaryColor: "333",
                        textColor: "333",
                      }}
                    ></InlineWidget> */}
     
    </Box>
  );
};

export default Reviews;
