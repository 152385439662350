import React from 'react';

function Tactic(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const strokewidth = props.strokewidth || 1;
	const width = props.width || '100%';
	const height = props.height || '100%';
	const title = props.title || "tactic";

	return (
		<svg height={height} width={width} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
	<title>{title}</title>
	<g>
		<path d="M4.293,16.707a1,1,0,0,0,1.414,0L11,11.414l5.293,5.293a1,1,0,0,0,1.414-1.414L12.414,10l5.293-5.293a1,1,0,1,0-1.414-1.414L11,8.586,5.707,3.293A1,1,0,0,0,4.293,4.707L9.586,10,4.293,15.293A1,1,0,0,0,4.293,16.707Z" fill="#e0e0e0"/>
		<path d="M44.707,30.293a1,1,0,0,0-1.414,0L38,35.586l-5.293-5.293a1,1,0,0,0-1.414,1.414L36.586,37l-5.293,5.293a1,1,0,1,0,1.414,1.414L38,38.414l5.293,5.293a1,1,0,0,0,1.414-1.414L39.414,37l5.293-5.293A1,1,0,0,0,44.707,30.293Z" fill="#e0e0e0"/>
		<path d="M45,2H31a1,1,0,0,0-.707,1.707L36.586,10,22.293,24.293a1,1,0,1,0,1.414,1.414L38,11.414l6.293,6.293A1,1,0,0,0,46,17V3A1,1,0,0,0,45,2Z" fill="#ffcb00"/>
		<circle cx="11" cy="37" fill="#a0a0a0" r="9"/>
	</g>
</svg>
	);
};

export default Tactic;