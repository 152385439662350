/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { useTheme } from "@mui/material/styles";
import { Avatar, Button, colors } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import Carousel from "react-material-ui-carousel";

const CaseStudy2 = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  return (
    <Box
      padding={{ xs: 2, sm: 4 }}
      borderRadius={0}
      bgcolor={"#f5f5f5"}
      data-aos={"fade-up"}
    >
       <Typography fontWeight={700} variant={"h4"} mb={3}>#1 Case Study - walkingboxes GmbH</Typography>
      <Grid
        container
        spacing={isMd ? 4 : 2}
        flexDirection={{ xs: "column-reverse", md: "row" }}
      >
        <Grid item xs={12} md={4}>
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={{ xs: "flex-start", md: "space-between" }}
            height={1}
          >
            <Box>
              <Typography variant={"h6"} fontWeight={700} gutterBottom>
                Problem
              </Typography>
              <Typography

              // color={
              //   theme.palette.mode === 'light'
              //     ? 'text.secondary'
              //     : 'text.primary'
              // }
              >
                Walkingboxes erhielt zwar eine vierstellige Anzahl Anfragen pro Jahr, aber
                davon konnten nur wenige erfolgreich zum Kaufabschluss gebracht
                werden. Da die Qualität der Anfragen oftmals nicht gut war, war das
                Nachfassen sehr mühsam.
              </Typography>
            </Box>
            <Box
              component={Card}
              marginTop={{ xs: 2, md: 2 }}
              boxShadow={0}
              bgcolor={"white"}
              borderRadius={0}
            >
              <CardContent sx={{ padding: { xs: 2, sm: 4 } }}>
                <center>
                  <Avatar
                    src={
                      "https://firebasestorage.googleapis.com/v0/b/yellow-web-6310c.appspot.com/o/daniel_lorch.jpg?alt=media&token=7b920ab4-503f-4b82-863e-ad9e50acb798"
                    }
                    sx={{ marginRight: 1, height: "140px", width: "140px" }}
                  />
                </center>
                <br />
                <Typography component={"p"}>
                  Als mich Johannes angerufen hatte, war für mich schnell klar,
                  dass ein 3D-Konfigurator für den Vertrieb unserer Food Trailer ein Game Changer sein wird. Unsere Branche ist umkämpft, sodass der Konfigurator für uns ein wichtiger Wettbewerbsvorteil ist. Ich wurde nicht enttäuscht und bin mit dem Ergebnis mehr als zufrieden.
                </Typography>
                <Box marginTop={{ xs: 2, sm: 4 }}>
                  <Typography variant={"subtitle1"} sx={{ fontWeight: 700 }}>
                    Daniel Lorch
                  </Typography>
                  <Typography color="text.secondary">
                    Geschäftsführer
                  </Typography>
                </Box>
                <br />
                <Box
                  component="img"
                  height={1}
                  width={1}
                  src={
                    "https://firebasestorage.googleapis.com/v0/b/yellow-web-6310c.appspot.com/o/walkingboxes-logo-hoch.png?alt=media&token=27786c24-dd13-42ea-a7a8-bd3e57c54959"
                  }
                  alt="..."
                  maxWidth={80}
                  marginBottom={2}
                  sx={{
                    filter:
                      theme.palette.mode === "dark"
                        ? "brightness(0) invert(0.7)"
                        : "none",
                  }}
                />
                
              </CardContent>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={8}>
          <Grid container spacing={isMd ? 4 : 2}>
            <Grid item xs={12}>
          
              <Carousel navButtonsAlwaysVisible autoPlay={false}>
                {[
                  <Box
                    component={"img"}
                    loading="lazy"
                    height={1}
                    width={1}
                    src={
                      "https://firebasestorage.googleapis.com/v0/b/yellow-web-6310c.appspot.com/o/wb_mock.jpg?alt=media&token=aae24b0f-be96-4895-8745-858482394a0d"
                    }
                    alt="..."
                    borderRadius={0}
                    maxWidth={1}
                    height={400}
                    sx={{
                      objectFit: "cover",
                    }}
                  />,
                  <Box
                    component={"img"}
                    loading="lazy"
                    height={1}
                    width={1}
                    src={
                      " https://firebasestorage.googleapis.com/v0/b/yellow-web-6310c.appspot.com/o/wb_1.png?alt=media&token=7f4e2c9c-e4c4-4180-93b8-f27cb2c3bce3"
                    }
                    alt="..."
                    borderRadius={0}
                    maxWidth={1}
                    height={550}
                    sx={{
                      objectFit: "cover",
                    }}
                  />,
                  <Box
                    component={"img"}
                    loading="lazy"
                    height={1}
                    width={1}
                    src={
                      "https://firebasestorage.googleapis.com/v0/b/yellow-web-6310c.appspot.com/o/wb_2.png?alt=media&token=1fadc7c7-42fd-4732-9828-a601e66a6a9d"
                    }
                    alt="..."
                    borderRadius={0}
                    maxWidth={1}
                    height={550}
                    sx={{
                      objectFit: "cover",
                    }}
                  />,
                  // <iframe width="100%" height="400" src="https://www.youtube.com/embed/kYg9B7PDUok?si=7pv4WctzKbGxgv0u" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>,
                  
                <Box
                component={"img"}
                loading="lazy"
                height={1}
                width={1}
                src={
                  "https://www.walkingboxes.de/wp-content/uploads/2022/10/walkingboxes-foodtrailer_WB-011-M_DSC00568-1024x760.jpg"
                }
                alt="..."
                borderRadius={0}
                maxWidth={1}
                height={400}
                sx={{
                  objectFit: "cover",
                }}
              />,
                
                  <Box
                    component={"img"}
                    loading="lazy"
                    height={1}
                    width={1}
                    src={
                      "https://firebasestorage.googleapis.com/v0/b/yellow-web-6310c.appspot.com/o/wb_customer_story.jpg?alt=media&token=ac3874fb-ecd4-45f9-98ab-28cffc63a69d"
                    }
                    alt="..."
                    borderRadius={0}
                    maxWidth={1}
                    height={400}
                    sx={{
                      objectFit: "cover",
                    }}
                  />,
                ].map((item, i) => item)}
              </Carousel>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant={"h6"} fontWeight={700} gutterBottom>
                Ziel
              </Typography>
              <Typography component={"p"}>
                Einerseits werden passendere Anfragen benötigt und andererseits sollen Interessenten stärker an Walkingboxes gebunden werden, damit diese nicht zur Konkurrenz abwandern.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant={"h6"} fontWeight={700} gutterBottom>
                Lösung
              </Typography>
              <Typography component={"p"}>
                Mit dem 3D-Konfigurator werden Interessenten stärker gebunden. Gleichzeitig verbessert sich die Qualität der Anfragen deutlich.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <center>
                <a href={"https://www.walkingboxes.de/konfiguriere-deinen-premium-foodtrailer/"} target={"_blank"}>
                  <Button
                    sx={{
                      "&:hover": { background: "#222222" },
                      background: "#222222",
                      color: "white",
                      width: "230px",
                      borderRadius: "22px",
                    }}
                  >
                    Zum Konfigurator
                  </Button>
                </a>
              </center>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CaseStudy2;
