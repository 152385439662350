import { Button } from "@mui/joy";
import React, { useEffect, useRef, useState } from "react";

const ConfiguratorUploadField = () => {
    const inputFile = useRef(null)
    const [file, setFile] = useState(null);
    useEffect(()=>{
        console.log(inputFile)
    },[inputFile])
    return <>
    <input type='file' id='file' ref={inputFile} style={{display: 'none'}}/>
    <img style={{margin: "auto", marginBottom: "10px", marginTop: "25px"}} height={"75px"}  
    src={"https://work.alive.com/wp-content/themes/mytheme/assets/images/placeholder-logo.jpg"}/>
    {file && file.files[0].name}
    <Button onClick={() => {
  // `current` points to the mounted file input element
  inputFile.current.click();
  
}}>UPLOAD</Button></>
}

export default ConfiguratorUploadField;