import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import useMediaQuery from '@mui/material/useMediaQuery';
import { alpha, useTheme } from '@mui/material/styles';
import { colors } from '@mui/material';

const mock = [
  {
    title: 'Konzept',
    description:
      'Wir lernen Dein Unternehmen und Deine Produkte kennen und erstellen ein maßgeschneidertes Konzept für Dich.',
    illustration:
      'https://assets.maccarianagency.com/svg/illustrations/illustration4.svg',
    illustrationDark:
      'https://assets.maccarianagency.com/svg/illustrations/illustration4--dark.svg',
    color: colors.blue[200],
  },
  {
    title: 'Umsetzung',
    description:
      'Wir setzen die erste 3D-Experience für Dich um, die Du sofort auf Deiner Website integrieren kannst.',
    illustration:
      'https://assets.maccarianagency.com/svg/illustrations/illustration3.svg',
    illustrationDark:
      'https://assets.maccarianagency.com/svg/illustrations/illustration3--dark.svg',
    color: colors.purple[200],
  },
  {
    label: 'Onboarding',
    title: 'Onboarding',
    description:
      'Wir schulen Dich und Deine Mitarbeiter in der optimalen Benutzung des 3D-Konfigurators in Vertrieb und Marketing.',
    illustration:
      'https://assets.maccarianagency.com/svg/illustrations/illustration2.svg',
    illustrationDark:
      'https://assets.maccarianagency.com/svg/illustrations/illustration2--dark.svg',
    color: colors.indigo[200],
  },
  {
    title: 'Strategische Weiterentwicklung',
    description:
      'Wir sammeln kontinuierlich Feedback von Dir und Deinen Kunden und machen Deinen Online-Vertrieb noch erfolgreicher.',
    illustration:
      'https://assets.maccarianagency.com/svg/illustrations/illustration1.svg',
    illustrationDark:
      'https://assets.maccarianagency.com/svg/illustrations/illustration1--dark.svg',
    color: colors.green[200],
  },
];

const Solutions = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Box>
      {/* <Box marginBottom={4}>
        <Typography
          sx={{
            textTransform: 'uppercase',
            fontWeight: 'medium',
          }}
          gutterBottom
          color={'secondary'}
        >
          Solutions
        </Typography>
        <Typography
          variant="h4"
          data-aos={'fade-up'}
          gutterBottom
          sx={{
            fontWeight: 700,
          }}
        >
          Your new website marketing solution is here
        </Typography>
        <Typography variant="h6" color={'text.secondary'} data-aos={'fade-up'}>
          theFront Kit offers a completely customizable website landing pages
          and supported pages management platform.
          <br />
          Here’s what makes us different.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          size="large"
          sx={{ marginTop: 2 }}
        >
          View all
        </Button>
      </Box> */}
      <Grid container spacing={isMd ? 8 : 4}>
        {mock.map((item, i) => (
          <Grid
            key={i}
            item
            xs={12}
            md={6}
            data-aos="fade-up"
            data-aos-delay={i * 100}
            data-aos-offset={100}
            data-aos-duration={600}
          >
            <Box
              component={Card}
              height={1}
              className={"gradient-background"}
              // bgcolor={alpha(item.color, 0.2)}
              boxShadow={0}
              sx={{
                //backgroundImage: "linear-gradient(to top, #c4c5c7 0%, #dcdddf 52%, #ebebeb 100%)",
                transform: i % 2 === 1 ? { md: 'translateY(80px)' } : 'none',
              }}
            >
              <CardContent sx={{ padding: 4 }}>
                <Box
                  display={'flex'}
                  justifyContent={'center'}
                  marginBottom={{ xs: 2, md: 4 }}
                >
                  {/* <Box
                    component={'img'}
                    loading="lazy"
                    src={
                      theme.palette.mode === 'light'
                        ? item.illustration
                        : item.illustrationDark
                    }
                    width={1}
                  /> */}
                    <Typography variant={"h2"} sx={{fontWeight: "bold", textalign: "left", mb:"-30px", ml: "auto", color: "#111", textShadow: "0 0 2px #ccc",}}>{i+1}</Typography>
                </Box>
                <Box>
                  <Typography
                   
                    gutterBottom
                    sx={{ fontWeight: 700, color: "#ccc" }}
                  >
                    {item.title}
                  </Typography>
                  <Typography color="text.secondary">
                    {item.description}
                  </Typography>
                  {/* <Button
                    size={'large'}
                    sx={{ marginTop: 2 }}
                    endIcon={
                      <Box
                        component={'svg'}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        width={24}
                        height={24}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M17 8l4 4m0 0l-4 4m4-4H3"
                        />
                      </Box>
                    }
                  >
                    Learn more
                  </Button> */}
                </Box>
              </CardContent>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Solutions;
