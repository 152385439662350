import * as React from "react";
import Avatar from "@mui/joy/Avatar";
import FormLabel from "@mui/joy/FormLabel";
import Radio, { radioClasses } from "@mui/joy/Radio";
import RadioGroup from "@mui/joy/RadioGroup";
import Sheet from "@mui/joy/Sheet";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { Box, Card, Grid } from "@mui/material";
import { PrimaryColor, primaryColor } from "Routes";
import InfoIcon from "@mui/icons-material/Info";
import FadeInModal from "FadeInModal";
import { useState } from "react";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import IconButton from "@mui/joy/IconButton";
import Menu from "@mui/joy/Menu";
import MenuItem from "@mui/joy/MenuItem";
import Apps from "@mui/icons-material/Apps";
import { PropaneSharp } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import CurrencyFormat from "react-currency-format";
import { Chip } from "@mui/joy";
import Pen from "icons/pen";
import ERemove from "icons/e-remove";
import Cursor49 from "icons/cursor-49";
import { useTour } from "@reactour/tour";
import useWindowSize from "useWindowSize";

export function AppsMenu(props) {
  const buttonRef = React.useRef(null);
  const [open, setOpen] = React.useState(false);
  const innerWidth = useWindowSize();
  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    setOpen(props.open == props.segmentTitle);
  }, [props.open]);

  return (
    <Box
      sx={{ display: "inline-block", background: "white", marginTop: "15px" }}
      ref={buttonRef}
      id="apps-menu-demo"
      aria-label="Applications"
      aria-controls={"apps-menu"}
      aria-haspopup="true"
      aria-expanded={open ? "true" : undefined}
      variant="plain"
      color="neutral"
      onClick={() => {
        setOpen(!open);
      }}
    >
      {/* <Apps />
      </IconButton> */}
      {props.children}
      <Menu
        id="apps-menu"
        variant="solid"
        loading={"eager"}
        invertedColors
        anchorEl={buttonRef.current}
        open={open}
        onClose={handleClose}
        aria-labelledby="apps-menu-demo"
        sx={{
          "--List-padding": "0.5rem",
          "--ListItemDecorator-size": "3rem",
          display: "grid",
          gridTemplateColumns:
            "repeat(" + (innerWidth > 800 ? "3" : "2") + (innerWidth > 800 ? ", 150px)": ", calc(50% - 0.5rem))"),
          gridAutoRows: "100px",
          gap: 1,
          backgroundColor: "white",
          maxHeight: (innerWidth > 800 ? "auto" : "calc(90vh - 155px)"),
          width: (innerWidth > 800 ? "auto" : "100vw"),
        }}
      >
        {props.choices.map((choice) => {
          return (
            <MenuItem
            loading={"eager"}
              orientation="vertical"
              className={choice.selector ? choice.selector : choice.title}
              onClick={() => props.setCurrentValue(choice)}
              sx={{
                color: "#888",
                transition: "all .1s ease-in-out",
                "&:hover": { color: "black", background: "#eee" },
              }}
            >
              <ListItemDecorator  loading={"eager"}>
                <Avatar
                loading={"eager"}
                  sx={{
                    background: "transparent",
                    height: "34px",
                    width: "auto",
                    borderRadius: 0,
                    loading: "eager"
                  }}
                >
                  <img
                    height={"34px"}
                    loading="eager"
                    src={choice.image}
                    style={{
                      objectFit: "contain",
                      //width: "40px",
                      height: "34px",
                    }}
                  />
                </Avatar>
              </ListItemDecorator>
              <span
                style={{
                  marginTop: "-10px",
                  fontSize: "0.75rem",
                  color: "#363636",
                  // choice.verboseTitle && choice.verboseTitle.length > 12
                  //   ? choice.verboseTitle.length > 15 ?"0.75rem":"0.85rem"
                  //   : "1rem",
                }}
              >
                {Array.isArray(choice.title)
                  ? choice.verboseTitle
                  : choice.verboseTitle
                  ? choice.verboseTitle
                  : choice.title}
              </span>
              {(choice.price || choice.price == 0) && (
                <small>
                  <CurrencyFormat
                    suffix={" €"}
                    displayType={"text"}
                    decimalSeparator={","}
                    thousandSeparator={"."}
                    value={choice.price}
                  />
                </small>
              )}
            </MenuItem>
          );
        })}

        {/* <MenuItem orientation="vertical" onClick={handleClose}>
          <ListItemDecorator>
            <Avatar>M</Avatar>
          </ListItemDecorator>
          Maps
        </MenuItem>
        <MenuItem orientation="vertical" onClick={handleClose}>
          <ListItemDecorator>
            <Avatar>M</Avatar>
          </ListItemDecorator>
          Mail
        </MenuItem>
        <MenuItem orientation="vertical" onClick={handleClose}>
          <ListItemDecorator>
            <Avatar>D</Avatar>
          </ListItemDecorator>
          Drive
        </MenuItem>
        <MenuItem orientation="vertical" onClick={handleClose}>
          <ListItemDecorator>
            <Avatar>C</Avatar>
          </ListItemDecorator>
          Calendar
        </MenuItem> */}
      </Menu>
    </Box>
  );
}

export default function ConfiguratorExtendableSelect({
  options,
  setCurrentValue,
  currentValue,
  largeMenu,
  field,
  submitted,
  configuration,
  parametrization,
  setConfiguration,
}) {
  const [menuOpen, setMenuOpen] = useState("segmentTitle");
  const [infoModalText, setInfoModalText] = useState(null);
  const [infoModalOpen, setInfoModalOpen] = useState(false);
  const innerWidth = useWindowSize();
const {isOpen} = useTour();
  let pc = PrimaryColor();

  React.useEffect(() => {
    console.log("VALUE ISSSSS ", currentValue);
  }, [currentValue]);
  return (
    <>
      <Box
        className={"select-options-container"}
        sx={{
          // paddingLeft: options.length  > 2 && innerWidth <800 ? "calc(50vw - 87px)" : "0px",
          height: "130px",
          // display: "flex",
          //margin: "auto",
          pointerEvents: submitted?"none":"auto",
          flexDirection: "horizontal",
          textAlign: "left",
          overflowX: innerWidth > 800 ? "scroll" : "scroll",
          overflowY: "hidden",
          whiteSpace: "nowrap",
          scrollbarWidth: "none",
          width:
            innerWidth > 800
              ? 230 * field.segmentTitles.length + 10 + "px"
              : "auto",
              paddingTop: "4px",
          // width: (innerWidth > 800 ? "auto" : "250px")
        }}
      >
        <FadeInModal
          childrenHtml={infoModalText}
          open={infoModalOpen}
          setOpen={setInfoModalOpen}
        />

        <Box
          sx={{
            margin: "auto",
            overflow: "hidden",
            display: innerWidth < 800 && largeMenu ? "inline" : "inline",
          }}
        >
          {/* <AppsMenu/> */}
          <Box
            sx={{
              display:
                innerWidth > 800 ? "inline-block" : "inline-block",
            }}
          >
            <div
              style={{
                height: "0px",
                width: "100%",
                marginBottom: "-8px",
                marginLeft: "-8px",
                textAlign: "right",
                zIndex: 100,
              }}
            ></div>
          </Box>
          <div
            style={{
              marginBottom: field.segmentPrices ? "-15px" : "-15px",
              marginTop: "-20px",
            }}
          >
          
            {field.segmentTitles &&
              field.segmentTitles.map((segment, index) => {
                return (
                  <Box
                    sx={{
                      width: "230px",
                      textAlign: "center",
                      display: "inline-block",
                      lineHeight: "1rem",
                    }}
                  >
                    {segment}
                    {field.segmentPrices && !field.hideSegmentPrices && (
                      <small
                        style={{
                          display: "block",
                          color: "#888",
                          height: "0px",
                          fontSize: "0.75rem",
                        }}
                      >
                        <CurrencyFormat
                          suffix={" €"}
                          displayType={"text"}
                          decimalSeparator={","}
                          thousandSeparator={"."}
                          value={field.segmentPrices[index].price}
                        />
                      </small>
                    )}
                  </Box>
                );
              })}
          </div>
          {currentValue &&
            Array.isArray(currentValue) &&
            currentValue
              // .filter((i) => i
              // // && !(i.includes("_2") || i.includes("_3"))
              // )
              .map((value, index) => {
                console.log("debug this:", value, field.choices, currentValue);
                let price = 0;

                if (value != "placeholder") {
                  price = field.choices.filter(
                    (i) =>
                      i.title == value ||
                      (Array.isArray(i.title) && i.title.includes(value))
                  )[0].price;
                }
                if (value != "placeholder") {
                  return (
                    <AppsMenu
                      choices={field.choices}
                      segmentTitle={field.segmentTitles[index]}
                      open={menuOpen}
                      //   setCurrentValue={setCurrentValue}
                      setCurrentValue={(choice) => {
                        console.log(
                          "new length ",
                          currentValue.map((i) => i).length +
                            choice.span -
                            field.choices.filter(
                              (i) =>
                                i.title == value ||
                                (Array.isArray(i.title) &&
                                  i.title.includes(value))
                            )[0].span,
                          field.maximum
                        );
                        if (
                          // this does not work properly when an element is replaced. it needs to detext when the change is at the end of the array and really necessary.
                          // CHECK IF THE CHOSEN ELEMENT FITS INTO MAX LENGTH
                          currentValue.map((i) => i).length +
                            choice.span -
                            field.choices.filter(
                              (i) =>
                                i.title == value ||
                                (Array.isArray(i.title) &&
                                  i.title.includes(value))
                            )[0].span <
                            field.maximum ||
                          index + choice.span < field.maximum
                        ) {

                          if (!value.includes("2er") && !value.includes("3er")) {
                            // THE CURRENT VALUE IS NOT OF LENGTH 2
                            if (choice.span == 1) {
                              let newValue = [...currentValue];

                              newValue[index] = choice.title;
                              console.log("NWWWWWW", newValue);
                              setCurrentValue(newValue);
                          
                            }
                            if (choice.span == 2) {
                              let newValue = [...currentValue];
                              newValue[index] = choice.title;
                              newValue[index + 1] = "placeholder";
                              let nextElement = field.choices.filter(
                                (i) => i.title == currentValue[index + 1]
                              );
                              console.log(
                                "next element is",
                                index,
                                currentValue,
                                choice,
                                field.choices,
                                nextElement
                              );
                              if (
                                nextElement.length > 0 &&
                                nextElement[0].span == 2
                              ) {
                                newValue[index + 2] = field.standardReplacement;
                              }
                              if (
                                nextElement.length > 0 &&
                                nextElement[0].span == 3
                              ) {
                                newValue[index + 2] = field.standardReplacement;
                                newValue[index + 3] = field.standardReplacement;
                              }
                              console.log("setting is ", newValue);
                              console.log("NWWWWWW", newValue);
                              setCurrentValue(newValue);
                            }

                            if (choice.span == 3) {
                              console.log("NEW LENGTH: 3")
                              let newValue = [...currentValue];
                              newValue[index] = choice.title;
                              newValue[index + 1] = "placeholder";
                              newValue[index + 2] = "placeholder";
                              let nextElement = field.choices.filter(
                                (i) => i.title == currentValue[index + 1]
                              );
                              console.log(
                                "next element is",
                                index,
                                currentValue,
                                choice,
                                field.choices,
                                nextElement
                              );
                              if (
                                nextElement.length > 0 &&
                                nextElement[0].span == 2
                              ) {
                                newValue[index + 2] = field.standardReplacement;
                              }
                              if (
                                nextElement.length > 0 &&
                                nextElement[0].span == 3
                              ) {
                                newValue[index + 2] = field.standardReplacement;
                                newValue[index + 3] = field.standardReplacement;
                              }
                              console.log("setting is ", newValue);
                              console.log("NWWWWWW", newValue);
                              setCurrentValue(newValue);
                            }

                          } else if(value.includes("2er")) {
                            // THE CURRENT VALUE IS OF LENGTH 2
                            if (choice.span == 2) {
                              let newValue = [...currentValue];

                              newValue[index] = choice.title;
                              newValue[index + 1] = "placeholder";
                              console.log("new valllllu", newValue);
                              console.log("NWWWWWW", newValue);
                              setCurrentValue(newValue);
                            }
                            if (choice.span == 1) {
                              let newValue = [...currentValue];
                              newValue[index] = choice.title;
                              newValue[index + 1] = field.standardReplacement;
                              console.log("new valllllu", newValue);
                              setCurrentValue(newValue);
                            }
                          }else if(value.includes("3er")) {
                            // THE CURRENT VALUE IS OF LENGTH 2
                            if (choice.span == 3) {
                              let newValue = [...currentValue];

                              newValue[index] = choice.title;
                              newValue[index + 1] = "placeholder";
                              newValue[index + 2] = "placeholder";
                              console.log("new valllllu", newValue);
                              console.log("NWWWWWW", newValue);
                              setCurrentValue(newValue);
                            }
                            if (choice.span == 2) {
                              let newValue = [...currentValue];

                              newValue[index] = choice.title;
                              newValue[index + 1] = "placeholder";
                              newValue[index + 2] = field.standardReplacement;
                              console.log("new valllllu", newValue);
                              console.log("NWWWWWW", newValue);
                              setCurrentValue(newValue);
                            }
                            if (choice.span == 1) {
                              let newValue = [...currentValue];

                              newValue[index] = choice.title;
                              newValue[index + 1] = field.standardReplacement;
                              newValue[index + 2] = field.standardReplacement;
                              console.log("new valllllu", newValue);
                              console.log("NWWWWWW", newValue);
                              setCurrentValue(newValue);
                            }
                          }
                        }
                      }}
                    >
                      <Box
                        sx={{
                          width:
                            value.includes("2er") && !value.includes("_2")
                              ? "460px"
                              : 
                            value.includes("3er") && !value.includes("_3")? "690px":
                              
                              "230px",
                          display:
                            innerWidth > 800
                              ? "inline-block"
                              : "inline-block",
                        }}
                      >
                        <div
                          style={{
                            height: "0px",
                            width: "100%",
                            marginBottom: "-8px",
                            marginLeft: "-8px",
                            textAlign: "right",
                            zIndex: 100,
                          }}
                        ></div>
                        <Box
                          variant="outlined"
                          sx={{
                            // border: "0.3px solid #ccc",
                            boxShadow: `rgba(0, 0, 0, 0.15) 0px 2px 8px`,

                            borderRadius: "8px",
                            bgcolor: "background.body",
                            boxShadow: `rgba(0, 0, 0, 0.15) 0px 2px 8px`,
                            background: "white",
                            cursor: "pointer",
                            color: "black",
                            marginTop: "-30px",
                           
                            padding: "8px",

                            //   display: "inline-block",
                            minWidth: 100,

                            height: "auto",
                            maxHeight: "150px",
                            // display: (!value.image? "flex": "auto"),

                            m: 1,
                            marginTop: "8px",
                            marginLeft: "8px",
                            marginRight: "8px",
                          }}
                        >
                          <Grid container sx={{ flexFlow: "wrap" }}>
                            <Grid
                              xs={12}
                              sx={{
                                display: "inline-block",
                                marginRight: "-5px",
                                textAlign: "right",
                                marginTop: "-4px",
                                height: "18px",
                                fontSize: "0.75rem",
                                "&:hover": { textDecoration: "underline" },
                              }}
                            >
                              <Chip
                                className={index == 0 ? "second-step" : ""}
                                color={"neutral"}
                                size="xs"
                                onClick={() => {
                                  setMenuOpen(field.segmentTitles[index]);
                                  console.log("open choices menu!");
                                }}
                                endDecorator={
                                  <span
                                    style={{
                                      marginLeft: "4px",
                                      marginRight: "-6px",
                                    }}
                                  >
                                    <Pen strokewidth={3.5} width={"10px"} />
                                  </span>
                                }
                                variant="plain"
                                sx={{ fontSize: "0.7rem", padding: "0 6px" }}
                              >
                                ändern
                              </Chip>
                            </Grid>
                            <Grid>
                              {" "}
                              {field.choices.filter(
                                (i) =>
                                  i.title == value ||
                                  (Array.isArray(i.title) &&
                                    i.title.includes(value))
                              )[0].image && (
                                <img
                                  src={
                                    field.choices.filter(
                                      (i) =>
                                        i.title == value ||
                                        (Array.isArray(i.title) &&
                                          i.title.includes(value))
                                    )[0].image
                                  }
                                  width={"60px"}
                                  height={
                                    innerWidth < 800 ? "25px" : "35px"
                                  }
                                  style={{ objectFit: "contain", height: innerWidth < 800 ? "25px" : "35px" }}
                                />
                              )}
                            </Grid>
                            <Grid>
                              {" "}
                              <Box
                                sx={{
                                  margin: "auto",
                                  display: "inline",
                                  fontSize: "0.85rem",
                                }}
                              >
                                {field.choices.filter(
                                  (i) =>
                                    i.title == value ||
                                    (Array.isArray(i.title) &&
                                      i.title.includes(value))
                                )[0].verboseTitle
                                  ? field.choices.filter(
                                      (i) =>
                                        i.title == value ||
                                        (Array.isArray(i.title) &&
                                          i.title.includes(value))
                                    )[0].verboseTitle
                                  : value.split("_")[0]}

                                {(price || price == 0) && (
                                  <small
                                    style={{
                                      marginTop: "-20px",
                                      display: "block",
                                      color: "#888",
                                    }}
                                  >
                                    <br />
                                    <CurrencyFormat
                                      suffix={" €"}
                                      displayType={"text"}
                                      decimalSeparator={","}
                                      thousandSeparator={"."}
                                      value={price}
                                    />
                                  </small>
                                )}
                              </Box>
                            </Grid>
                            <Grid
                              xs={12}
                              sx={{
                                display: "inline-block",
                                marginLeft: "10px",
                                textAlign: "right",
                                fontSize: "0.75rem",
                                height: "15px",
                                "&:hover": { textDecoration: "underline" },
                              }}
                            >
                              {currentValue.filter((i) => i).length -
                                field.choices.filter((i) =>
                                  value.includes(i.title)
                                )[0].span >=
                                field.minimum && (
                                <Chip
                                  className={index == 0 ? "remove-element" : ""}
                                  onClick={(e) => {
                                    if(!isOpen){
                                      e.preventDefault();
                                      e.stopPropagation();
                                    }
                                   
                                    //   console.log("span iss", value, field.choices.filter((i) =>
                                    //   i.verboseTitle?value.includes(i.verboseTitle):value.includes(i.title)
                                    // )[0].span)
                                    if (
                                      currentValue.filter((i) => i).length -
                                        field.choices.filter(
                                          (i) =>
                                            i.title == value ||
                                            (Array.isArray(i.title) &&
                                              i.title.includes(value))
                                        )[0].span >=
                                      field.minimum
                                    ) {
                                      if (value.includes("2er")) {
                                        let newValue = [...currentValue];
                                        newValue.splice(index, 2);
                                        setCurrentValue(newValue);
                                        //TBD deletion synchronization logic for elements of length :
                                     

                                      } else {
                                        let newValue = [...currentValue];
                                        newValue.splice(index, 1);
                                        setCurrentValue(newValue);
                                        // deletion synchronization logic for elements of length :
                                        console.log("deletion", field, configuration)
                                        if(field.synchronizeWith){
                                          for(let syncField of field.synchronizeWith){
                                            // let cfg = {...configuration}
                                            let foundAt;
                                            let newValue = configuration.filter((i,index) => {if(i.title == syncField){
                                                    foundAt=index
                                                    return true
                                            }})[0].currentValue;
                                            newValue.splice(newValue.length - 1, 1);
                                            let newConfig = [...configuration]
                                            newConfig[foundAt]={...newConfig[foundAt], currentValue:newValue}
                                            console.log("new configuration after deletion on synched", syncField, newConfig)
                                            setConfiguration(newConfig);
                                            
                                          }
                                        }
                                      }
                                     
                                    }
                                  }}
                                  color={"neutral"}
                                  size="xs"
                                  endDecorator={
                                    <span
                                      style={{
                                        marginLeft: "4px",
                                        marginRight: "-6px",
                                      }}
                                    >
                                      <ERemove
                                        strokewidth={3.5}
                                        width={"10px"}
                                      />
                                    </span>
                                  }
                                  variant="plain"
                                  sx={{ fontSize: "0.7rem", padding: "0 6px" }}
                                >
                                  entfernen
                                </Chip>
                              )}
                            </Grid>
                          </Grid>
                        </Box>
                      </Box>
                    </AppsMenu>
                  );
                } else {
                  return <></>;
                }
              })}
          {currentValue &&
            Array.isArray(currentValue) &&
            currentValue.filter((i) => i).length <
              field.segmentTitles.length && (
              <AppsMenu
                className={"first-step-parent"}
                choices={field.choices}
                segmentTitle={
                  currentValue &&
                  Array.isArray(currentValue) &&
                  field.segmentTitles[currentValue.filter((i) => i).length]
                }
                open={menuOpen}
                setCurrentValue={(choice) => {
                  console.log(
                    "spaan ",
                    field.choices.filter(
                      (i) =>
                        i.title == choice.title ||
                        (Array.isArray(i.title) &&
                          i.title.includes(choice.title))
                    )[0].span
                  );
                  if (
                    currentValue.filter((i) => i).length +
                      field.choices.filter(
                        (i) =>
                          i.title == choice.title ||
                          (Array.isArray(i.title) &&
                            i.title.includes(choice.title))
                      )[0].span <
                    field.maximum
                  ) {
                    if (choice.span == 1) {
                      let newValue = [...currentValue];
                      newValue.push(choice.title);
                      setCurrentValue(newValue);
                      if(field.synchronizeWith){
                        for(let syncField of field.synchronizeWith){
                          let foundAt;
                          let targetField = configuration.filter((i,index) => {if(i.title == syncField){
                            foundAt=index
                            return true
                    }})[0]
                          let newValue = targetField.currentValue;
                          console.log("synchronizing", syncField, newValue)
                          let replacement = choice.title
                          //newValue = [replacement, ...newValue]
                          if(targetField.standardReplacement && targetField.choices.filter(c => c.title == replacement).length == 0){
                            replacement = targetField.standardReplacement
                          }
                          newValue.push(replacement)
                          console.log("synchronizing", syncField, newValue)
                          let newConfig = [...configuration]
                          newConfig[foundAt]={...newConfig[foundAt], currentValue:newValue}
                          console.log("new configuration after adding on synched", syncField, newConfig)
                          setConfiguration(newConfig);
                        }
                      }
                    }
                    if (choice.span == 2) {
                      let newValue = [...currentValue];
                      newValue.push(choice.title);
                      newValue.push("placeholder");
                      setCurrentValue(newValue);
                    }
                  }
                }}
              >
                {!field.disableAdd && <Box
                  className={"first-step"}
                  sx={{
                    cursor: "pointer",
                    display: "inline-block",
                    textAlign: "center",
                    width: "230px",
                    height: "83px",
                    marginTop: "1px",
                    borderRadius: "8px",
                    //  background: "linear-gradient(#eee, #dedede)",
                    //color: parametrization.branding.primaryColor.rgb,
                    position: "relative",
                    marginLeft: "8px",
                    verticalAlign: "top",
                    // border: ".15rem dashed #ccc" ,
                  }}
                >
                  <Box sx={{ marginTop: "0px", background: "#fafafa",display: "flex", borderRadius: "8px", border: "1px dashed #666666", height: "83px" }}>
                    <Box sx={{margin: "auto"}}>
                    <AddIcon sx={{color: "#333", marginBottom: "-0px", fontSize: "23px" }} />
                    {/* <span className={"animate__animated animate__fadeIn animate__infinite"} style={{display: "inline-block", fontSize: "18px", width: 0, marginBottom: "-10px"}}><Cursor49 strokewidth={4} width={"22px"}/></span>
                     */}
                    <span style={{color: "#333", fontWeight: "400", fontSize: "13px" }}>
                      HINZUFÜGEN
                    </span>
                    </Box>
                  </Box>
                </Box>}
              </AppsMenu>
            )}
        </Box>
      </Box>
    </>
  );
}
