import React from 'react';

function WirelessCharging(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const strokewidth = props.strokewidth || 1;
	const width = props.width || '100%';
	const height = props.height || '100%';
	const title = props.title || "wireless charging";

	return (
		<svg height={height} width={width} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
	<title>{title}</title>
	<g>
		<path d="M22,34a1,1,0,0,1-.97-1.242L22.719,26H18a1,1,0,0,1-.781-1.625l8-10a1,1,0,0,1,1.751.867L25.281,22H30a1,1,0,0,1,.781,1.625l-8,10A1,1,0,0,1,22,34Z" fill="#ffcb00"/>
		<path d="M39.557,40.557a1,1,0,0,1-.707-1.707,21,21,0,0,0,0-29.7,1,1,0,1,1,1.414-1.414,23,23,0,0,1,0,32.528A1,1,0,0,1,39.557,40.557Z" fill="#dddddd"/>
		<path d="M8.443,40.557a1,1,0,0,1-.707-.293,23,23,0,0,1,0-32.528A1,1,0,0,1,9.15,9.15a21,21,0,0,0,0,29.7,1,1,0,0,1-.707,1.707Z" fill="#dddddd"/>
		<path d="M14.1,34.9a.993.993,0,0,1-.707-.293,15,15,0,0,1,0-21.212,1,1,0,0,1,1.414,1.414,13,13,0,0,0,0,18.384A1,1,0,0,1,14.1,34.9Z" fill="#b2b2b2"/>
		<path d="M33.9,34.9a1,1,0,0,1-.707-1.707,13,13,0,0,0,0-18.384,1,1,0,1,1,1.414-1.414,15,15,0,0,1,0,21.212A.993.993,0,0,1,33.9,34.9Z" fill="#b2b2b2"/>
	</g>
</svg>
	);
};

export default WirelessCharging;