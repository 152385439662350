import React from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';

import Main from 'layouts/Main';
import Container from 'components/Container';
import {
  Content,
  FooterNewsletter,
  Hero,
  SidebarArticles,
  SidebarNewsletter,
  SimilarStories,
} from './components';
import {  collection, getFirestore, query, where } from 'firebase/firestore';
import { app } from 'index';
import { converter } from 'Config';
import { useParams } from 'react-router-dom';
import { useCollectionDataOnce } from 'react-firebase-hooks/firestore';
import { Reviews } from 'views/CloudHosting/components';
import { Avatar, Card } from '@mui/material';

const BlogArticle = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  let {slug} = useParams()
  const [articles, articlesLoading, articlesError] = useCollectionDataOnce(
    query(
      collection(getFirestore(app), "articles").withConverter(converter),
      where("slug", "==", slug)
    ),
    [],
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );
if(articles && articles.length){
  return (
    <Main>
      <Box>
        
        <Hero author={articles[0].author} title={articles[0].title} titleImage={articles[0].titleImage}/>
        <Container>
          <Grid container spacing={4} sx={{padding: window.innerWidth > 1100 ? "0 200px":"auto"}}>
            <Grid item xs={12} 
            // md={8}
            >
              <Content videoHTML={articles[0].videoHtml} title={articles[0].title} body={articles[0].body}/>
            </Grid>
            {/* <Grid item xs={12} md={4}>
              {isMd ? (
                <Box marginBottom={4}>
                  <SidebarArticles />
                </Box>
              ) : null}
              <SidebarNewsletter />
            </Grid> */}
          </Grid>
          <Box
      component={Card}
        sx={{
          margin: "0",
          // backgroundImage: "url(/undraw_group_video_re_btu7.svg)",
          // backgroundSize: "original",
          boxShadow: null,
          background: "#ffcb00",
          height: "auto"
        }}
      >
        <Container
          sx={{
            // background: "rgba(255,255,255,0.8)",
            margin: "auto",
            maxWidth: "100%",
            textAlign: "center"
          }}
        >
          <Grid container sx={{maxWidth: "950px", margin: "auto"}}>
            <Grid xs={12} md={6}>
            <Avatar
              sx={{width: "350px", background: "white",width: 250, height: 250, margin: "auto" }}
              src={
               '/johannes_dammrath_profile.png'
              }
            />
            </Grid>
<Grid xs={12} md={6} sx={{mt:5}}> <Reviews /></Grid>
          </Grid>
         
        </Container>
      </Box>
        </Container>
        <Box
          component={'svg'}
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 1920 100.1"
          sx={{
            marginBottom: -1,
            width: 1,
          }}
        >
          <path
            fill={theme.palette.alternate.main}
            d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
          ></path>
        </Box>
       
      </Box>
      {/* <Box bgcolor={'alternate.main'}>
        <Container>
          <SimilarStories />
        </Container>
        <Container>
          <FooterNewsletter />
        </Container>
        <Box
          component={'svg'}
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 1920 100.1"
          sx={{
            marginBottom: -1,
            width: 1,
          }}
        >
          <path
            fill={theme.palette.background.paper}
            d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
          ></path>
        </Box>
      </Box> */}
     
    </Main>
  );
}else{
  return <>loading...</>
}
};

export default BlogArticle;
