import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";

import NavItem from "./components/NavItem";
import { Typography } from "@mui/material";
// import { GoogleTranslate } from "views/CloudHosting/CloudHosting";

const SidebarNav = ({ pages }) => {
  const theme = useTheme();
  const { mode } = theme.palette;

  const {
    landings: landingPages,
    secondary: secondaryPages,
    company: companyPages,
    account: accountPages,
    portfolio: portfolioPages,
    blog: blogPages,
  } = pages;

  return (
    <Box sx={{zIndex: 999999999999}}>
      <Box width={1} paddingX={2} paddingY={1}>
        <Box
          display={"flex"}
          component="a"
          href="/"
          title="theFront"
          width={{ xs: 100, md: 120 }}
        >
          <Box component={"img"} src={"c_logo.png"} height={1} width={1} />
        </Box>
      </Box>
      <Box paddingX={2} paddingY={2}>
      <Box  >
          <Typography component={"a"} href={"/"} variant="h5" sx={{textDecoration: "none", color: "black", display: "block", paddingBottom: "20px"}} title={"Login"}>Start</Typography>
        </Box>
      <Box  >
          <Typography component={"a"} href={"/warum-3d"} variant="h5" sx={{textDecoration: "none", color: "black", display: "block", paddingBottom: "20px"}} title={"Login"}>3D Experiences</Typography>
        </Box>
      {/* <Box  >
          <Typography component={"a"} onClick={()=>window.open("https://ynflow.tawk.help/", "_blank")} variant="h5" sx={{textDecoration: "none", color: "black", display: "block", paddingBottom: "20px"}} title={"Login"}>Dokumentation</Typography>
        </Box> */}
        <Box  >
          <Typography component={"a"} href={"/login"} variant="h5" sx={{textDecoration: "none", color: "black", display: "block", paddingBottom: "20px"}} title={"Login"}>Login</Typography>
        </Box>
        {/* <Box  >
        <div id="google_translate_element_mobile" style={{zIndex: 5000, position: "relative", display: "inline"}}></div>
       

       <GoogleTranslate mobile/>
        </Box> */}
        {/* <Box>
          <NavItem title={'Company'} items={companyPages} />
        </Box>
        <Box>
          <NavItem title={'Pages'} items={secondaryPages} />
        </Box>
        <Box>
          <NavItem title={'Account'} items={accountPages} />
        </Box>
        <Box>
          <NavItem title={'Blog'} items={blogPages} />
        </Box>
        <Box>
          <NavItem title={'Portfolio'} items={portfolioPages} />
        </Box> */}
        {/* <Box marginTop={2}>
          <Button
            size={'large'}
            variant="outlined"
            fullWidth
            component="a"
            href="/docs/introduction"
          >
            Documentation
          </Button>
        </Box>*/}
        <Box marginTop={1}>
          <Button
            size={"large"}
            variant="contained"
            color="primary"
            fullWidth
            component="a"
            target="blank"
            sx={{   "&:hover": { background: "#ffcb00" },color: "black", borderRadius: "8px",
            fontWeight: "500",}}
            className={"butn butn__new"}
            href="https://calendly.com/configora/configora-demo"
            endIcon={
              <Box
                component={'svg'}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="black"
                width={24}
                height={24}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M17 8l4 4m0 0l-4 4m4-4H3"
                />
              </Box>
            }
          >
            Kostenlose Demo
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

SidebarNav.propTypes = {
  pages: PropTypes.object.isRequired,
};

export default SidebarNav;
