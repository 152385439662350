import CountUp from 'react-countup';
import React, { useCallback, useEffect, useRef, useState } from "react";
import debounce from 'lodash.debounce';
import { useCountUp } from 'react-countup';

// export default class CountTo extends React.Component {
// //   static propTypes = { to: PropTypes.number }
//   static defaultProps = { to: 0 }
//   state = { from: 0, to: this.props.to }

//   componentWillReceiveProps(nextProps) {
    
//     this.setState({
//       from: this.props.to, // The "old" target number
//       to: nextProps.to, // The "new" target number
//     })
//   }

//   render() {
//     return <CountUp duration={5} redraw  start={this.state.from} end={this.state.to} />
//   }
// }

const CountTo = props => {
  const prevValueRef = useRef();
  const countUpRef = React.useRef(null);
  // const [to, setTo] = useState(props.to)
  const [counting, setCounting] = useState(false)
  const { start, pauseResume, reset, update } = useCountUp({
    ref: countUpRef,
    start: prevValueRef.current,
    end:props.to,
    onStart: ()=>setCounting(true),
    onEnd:()=>setCounting(false),
    duration:0.7,
    suffix: props.suffix != undefined? props.suffix : " €",
    redraw: false,
    separator: ".",
    
  });
  // useEffect(() => {
  //   prevValueRef.current = props.to;
  // });

  useEffect(()=>{
    setCounting(true);
    updateTo(props.to);
    setCounting(false)
  },[props.to])

  const updateTo = useCallback(
    
    debounce(v => {
      console.log("received v:",v);
      setCounting(true);
      update(v)
    }, 100),
    []
  );

  return (
    <span style={{color: props.color? props.color : (counting? "#111": "#444"), fontWeight:counting? "500": "normal",  transition: "all .1s ease-in-out", fontSize:props.fontSize? props.fontSize: (counting? "17px": "16px") }} ref={countUpRef} />
  );
};

export default CountTo;