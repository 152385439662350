import React from 'react';

function NetworkConnection(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const strokewidth = props.strokewidth || 1;
	const width = props.width || '100%';
	const height = props.height || '100%';

	return (
		<svg height={height} width={width} viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
	<g fill={secondaryfill} stroke={secondaryfill} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10" strokeWidth={strokewidth}>
		<line fill="none" strokeLinecap="butt" x1="21.048" x2="42.949" y1="27.976" y2="17.025"/>
		<line fill="none" strokeLinecap="butt" x1="42.949" x2="21.048" y1="46.975" y2="36.024"/>
		<circle cx="13" cy="32" fill="none" r="9" stroke={fill}/>
		<circle cx="51" cy="13" fill="none" r="9" stroke={fill}/>
		<circle cx="51" cy="51" fill="none" r="9" stroke={fill}/>
	</g>
</svg>
	);
};

export default NetworkConnection;