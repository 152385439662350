import React from 'react';
import { createRoot, hydrateRoot } from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import App from './App';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { LicenseInfo } from '@mui/x-license-pro';

LicenseInfo.setLicenseKey('eb63344d4592e2e5a05868de77fd7170Tz03MDEzMixFPTE3MjAxODEyOTExNTMsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyBTmxMWFGNRQ5QtJ2XnPBlFxxSl368w6Gg",
  authDomain: "yellow-web-6310c.firebaseapp.com",
  projectId: "yellow-web-6310c",
  storageBucket: "yellow-web-6310c.appspot.com",
  messagingSenderId: "622819967778",
  appId: "1:622819967778:web:c10f46040744b59ae65b7b",
  measurementId: "G-J2JMB5QDN9"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);

const analytics = getAnalytics(app);

const root = hydrateRoot(document.getElementById('root'), <App/>);
//root = hydrateRoot(root,<App />);
// root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
