import React from 'react';

function Medal(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const strokewidth = props.strokewidth || 1;
	const width = props.width || '100%';
	const height = props.height || '100%';
	const title = props.title || "medal";

	return (
		<svg height={height} width={width} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
	<title>{title}</title>
	<g>
		<path d="M23.25,18.292,14.9,1.553A1,1,0,0,0,14,1H2a1,1,0,0,0-.895,1.447L12.375,25Z" fill="#e4e4e4"/>
		<path d="M24.75,18.292,33.1,1.553A1,1,0,0,1,34,1H46a1,1,0,0,1,.9,1.447L35.625,25Z" fill="#e4e4e4"/>
		<path d="M24,17A15,15,0,1,0,39,32,15.017,15.017,0,0,0,24,17Z" fill="#ffcb00"/>
		<polygon fill="#ffffff" points="24 23.91 26.629 29.236 32.507 30.09 28.253 34.236 29.257 40.09 24 37.326 18.743 40.09 19.747 34.236 15.493 30.09 21.371 29.236 24 23.91"/>
	</g>
</svg>
	);
};

export default Medal;