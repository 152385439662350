import React from 'react';

function ERemove(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const strokewidth = props.strokewidth || 1;
	const width = props.width || '100%';
	const height = props.height || '100%';
	const title = props.title || "e remove";

	return (
		<svg height={height} width={width} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
	<title>{title}</title>
	<g fill={secondaryfill} stroke={secondaryfill} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10" strokeWidth={strokewidth}>
		<line fill="none" stroke={fill} x1="38" x2="10" y1="10" y2="38"/>
		<line fill="none" stroke={fill} x1="38" x2="10" y1="38" y2="10"/>
	</g>
</svg>
	);
};

export default ERemove;