import React from 'react';

function Measure17(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const strokewidth = props.strokewidth || 1;
	const width = props.width || '100%';
	const height = props.height || '100%';

	return (
		<svg height={height} width={width} viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
	<g fill={secondaryfill} stroke={secondaryfill} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10" strokeWidth={strokewidth}>
		<circle cx="51" cy="17" fill="none" r="2"/>
		<line fill="none" x1="25" x2="31" y1="25" y2="31"/>
		<line fill="none" x1="19" x2="23" y1="31" y2="35"/>
		<line fill="none" x1="37" x2="43" y1="13" y2="19"/>
		<line fill="none" x1="31" x2="35" y1="19" y2="23"/>
		<line fill="none" x1="13" x2="19" y1="37" y2="43"/>
		<rect height="19.799" width="62.225" fill="none" stroke={fill} transform="matrix(0.7071 -0.7071 0.7071 0.7071 -13.2548 32)" x="0.887" y="22.101"/>
	</g>
</svg>
	);
};

export default Measure17;