import React from 'react';

function FloppyDisk(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const strokewidth = props.strokewidth || 1;
	const width = props.width || '100%';
	const height = props.height || '100%';

	return (
		<svg height={height} width={width} viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
	<g fill={secondaryfill} stroke={secondaryfill} strokeLinecap="round" strokeLinejoin="round" strokeWidth={strokewidth} transform="translate(0.5 0.5)">
		<polyline fill="none" points="40 11 40 23 12 23 12 11"/>
		<polyline fill="none" points="12 53 12 38 49 38 49 53"/>
		<line fill="none" x1="32" x2="32" y1="11" y2="17"/>
		<path d="M54,59H10a5,5,0,0,1-5-5V10a5,5,0,0,1,5-5H45L59,19V54A5,5,0,0,1,54,59Z" fill="none" stroke={fill}/>
	</g>
</svg>
	);
};

export default FloppyDisk;