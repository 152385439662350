import { responsiveFontSizes } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import shadows from './shadows';
import { light, dark } from './palette';

export const getTheme = (mode, themeToggler) =>
  responsiveFontSizes(
    createTheme({
      palette: mode === 'light' ? light : dark,
     
      // shadows: shadows(mode),
      typography: {
        fontFamily: '"Montserrat", sans-serif',
        button: {
          textTransform: 'none',
          fontWeight: 'medium',
          shape: {
            borderRadius: 4,
          },
        },
      },
     
      zIndex: {
        appBar: 1200,
        drawer: 1300,
      },
      shape: {
        borderRadius: 4,
      },
      
      components: {
        OutlinedInput: {
          shape: {
           borderRadius: 4,
         }
       },
        MuiButton: {
          styleOverrides: {
            root: {
              fontWeight: 400,
              borderRadius: 5,
              paddingTop: 10,
              paddingBottom: 10,
            },
            // containedSecondary: mode === 'light' ? { color: 'white' } : {},
          },
        },
        MuiInputBase: {
          styleOverrides: {
            root: {
              borderRadius: 5,
            },
          },
        },
        
        MuiOutlinedInput: {
          
          shape: {
            borderRadius: 16
           },
          styleOverrides: {
            root: {
              shape: {
                borderRadius: 16
               },
              borderRadius: 5,
            },
            input: {
              borderRadius: 5,
            },
           
          },
        },
        MuiCard: {
          styleOverrides: {
            root: {
              borderRadius: 8,
            },
          },
        },
        RadioGroup: {
          styleOverrides: {
            root: {
              borderRadius: 8,
            }
          }
          
        }
      },
      // themeToggler,
    }),
  );

export default getTheme;
