import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { useTheme, alpha } from "@mui/material/styles";
import './calculatorStyle.css';

import Main from "layouts/Main";
import { Container, Typography } from "@mui/material";
import { Folio } from "views/PortfolioPage/components";

const Warum3d = () => {
    useEffect(()=>{
     

// var frm = document.getElementById("kosten-nutzen-rechner")
// var otherhead = frm.getElementsByTagName("head")[0];
// var link = document.createElement("link");
// link.setAttribute("rel", "stylesheet");
// link.setAttribute("type", "text/css");
// link.setAttribute("href", "style.css");
// otherhead.appendChild(link);
    },[])
    return <Main>
        <Box sx={{marginTop: "150px"}}>
           <Container>
          <Typography
          variant={"h4"}
            sx={{
              marginBottom: "30px",
              marginTop: "60px",
              textAlign: "left",
              //fontSize: "40px",
              fontWeight: 700,
            }}
          >
            Du brauchst Inspiration? 
          </Typography>
          {/* <Typography mb={5} variant={"h6"}>
            3D-Visualisierungen verdeutlichen dem Kunden realitätsgetreu, welche
            Optionen es gibt und wie das Produkt aussieht, dass er kaufen wird.
            So wird schnell eine starke Kundenbindung erzeugt, die zu mehr
            Umsatz führt.{" "}
          </Typography> */}
          
          {/* <Box
            component={"img"}
            sx={{ maxWidth: "100%" }}
            src={"/truck2.jpeg"}
          ></Box>
          <Typography mb={5} mt={5} variant={"h6"}>
            Möchtest Du einen vollen 3D-Produktkonfigurator von uns sehen? Gerne
            vereinbaren wir einen exklusiven Demo-Termin mit Dir. Zur
            Terminbuchung kommst Du{" "}
            <a
              href={"https://calendar.app.google/q9TvtW389n7NsqaP6"}
              target={"_blank"}
            >
              hier
            </a>
            . Wir freuen uns auf Dich!
          </Typography> */}
          <Folio/>
        </Container>
        </Box>
    </Main>
}

export default Warum3d;