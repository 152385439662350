import React from 'react';

function MagicWand(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const strokewidth = props.strokewidth || 1;
	const width = props.width || '100%';
	const height = props.height || '100%';
	const title = props.title || "magic wand";

	return (
		<svg height={height} width={width} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
	<title>{title}</title>
	<g>
		<path d="M24.294 17.2939L2.29299 39.2939C1.90299 39.6849 1.90299 40.3169 2.29299 40.7079L7.29299 45.7079C7.68299 46.0979 8.31599 46.0979 8.70699 45.7079L30.708 23.7079L24.294 17.2939Z" fill="url(#nc-wand-0_linear_408_5)"/>
		<path d="M30.414 24L35.707 18.707C36.097 18.317 36.097 17.683 35.707 17.293L30.707 12.293C30.317 11.903 29.683 11.903 29.293 12.293L24 17.586L30.414 24Z" fill="url(#nc-wand-1_linear_408_5)"/>
		<path d="M45 32C42.791 32 41 30.209 41 28C41 27.447 40.553 27 40 27C39.447 27 39 27.447 39 28C39 30.209 37.209 32 35 32C34.447 32 34 32.447 34 33C34 33.553 34.447 34 35 34C37.209 34 39 35.791 39 38C39 38.553 39.447 39 40 39C40.553 39 41 38.553 41 38C41 35.791 42.791 34 45 34C45.553 34 46 33.553 46 33C46 32.447 45.553 32 45 32Z" fill="url(#nc-wand-2_linear_408_5)"/>
		<path d="M46 6C43.791 6 42 4.209 42 2C42 1.447 41.553 1 41 1C40.447 1 40 1.447 40 2C40 4.209 38.209 6 36 6C35.447 6 35 6.447 35 7C35 7.553 35.447 8 36 8C38.209 8 40 9.791 40 12C40 12.553 40.447 13 41 13C41.553 13 42 12.553 42 12C42 9.791 43.791 8 46 8C46.553 8 47 7.553 47 7C47 6.447 46.553 6 46 6Z" fill="url(#nc-wand-3_linear_408_5)"/>
		<path d="M20 7C17.791 7 16 5.209 16 3C16 2.447 15.553 2 15 2C14.447 2 14 2.447 14 3C14 5.209 12.209 7 10 7C9.447 7 9 7.447 9 8C9 8.553 9.447 9 10 9C12.209 9 14 10.791 14 13C14 13.553 14.447 14 15 14C15.553 14 16 13.553 16 13C16 10.791 17.791 9 20 9C20.553 9 21 8.553 21 8C21 7.447 20.553 7 20 7Z" fill="url(#nc-wand-4_linear_408_5)"/>
		<defs>
			<linearGradient id="nc-wand-0_linear_408_5" gradientUnits="userSpaceOnUse" x1="16.3542" x2="16.3542" y1="17.2939" y2="46.0004">
				<stop stopColor="#5B5E71"/>
				<stop offset="1" stopColor="#393A46"/>
			</linearGradient>
			<linearGradient id="nc-wand-1_linear_408_5" gradientUnits="userSpaceOnUse" x1="29.9998" x2="29.9998" y1="12.0005" y2="24">
				<stop stopColor="#A2A3B4"/>
				<stop offset="1" stopColor="#83849B"/>
			</linearGradient>
			<linearGradient id="nc-wand-2_linear_408_5" gradientUnits="userSpaceOnUse" x1="40" x2="40" y1="27" y2="39">
				<stop stopColor="#FFD36E"/>
				<stop offset="1" stopColor="#F6B828"/>
			</linearGradient>
			<linearGradient id="nc-wand-3_linear_408_5" gradientUnits="userSpaceOnUse" x1="41" x2="41" y1="1" y2="13">
				<stop stopColor="#FFD36E"/>
				<stop offset="1" stopColor="#F6B828"/>
			</linearGradient>
			<linearGradient id="nc-wand-4_linear_408_5" gradientUnits="userSpaceOnUse" x1="15" x2="15" y1="2" y2="14">
				<stop stopColor="#FFD36E"/>
				<stop offset="1" stopColor="#F6B828"/>
			</linearGradient>
		</defs>
	</g>
</svg>
	);
};

export default MagicWand;