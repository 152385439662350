/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { useTheme } from "@mui/material/styles";
import { Avatar, Button, colors } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import Carousel from "react-material-ui-carousel";

const CaseStudy1 = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  return (
    <Box
      padding={{ xs: 2, sm: 4 }}
      borderRadius={0}
      sx={{
        //backgroundImage: "linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%)",
        backgroundColor: "#f5f5f5"
      }}
      //bgcolor={"rgba(0, 0, 0, 0.08)"}
      data-aos={"fade-up"}
    >
      <Typography fontWeight={700} variant={"h4"} mb={3}>#2 Case Study - CUBE fx GmbH</Typography>
      <Grid
        container
        spacing={isMd ? 4 : 2}
        flexDirection={{ xs: "column-reverse", md: "row" }}
      >
        
        <Grid item xs={12} md={8}>
          <Grid container spacing={isMd ? 4 : 2}>
            <Grid item xs={12}>
              
              <Carousel navButtonsAlwaysVisible autoPlay={false}>
                {[
                  <Box
                    component={"img"}
                    loading="lazy"
                    height={1}
                    width={1}
                    src={
                      "https://firebasestorage.googleapis.com/v0/b/yellow-web-6310c.appspot.com/o/Gartensauna_SaunaCUBE_Galerie_oben_4.jpg?alt=media&token=f4d30552-f3f9-4ce2-8684-4f8f7a03640d"
                    }
                    alt="..."
                    borderRadius={0}
                    maxWidth={1}
                    height={400}
                    sx={{
                      objectFit: "cover",
                    }}
                  />,
                  <Box
                    component={"img"}
                    loading="lazy"
                    height={1}
                    width={1}
                    src={
                      "https://firebasestorage.googleapis.com/v0/b/yellow-web-6310c.appspot.com/o/cb_mock.jpg?alt=media&token=9355a844-f020-48d0-b66c-a57d76f48899"
                    }
                    alt="..."
                    borderRadius={0}
                    maxWidth={1}
                    height={400}
                    sx={{
                      objectFit: "cover",
                    }}
                  />,
                  <Box
                    component={"img"}
                    loading="lazy"
                    height={1}
                    width={1}
                    src={
                      " https://cube-fx.de/wp-content/uploads/2023/01/Beratungsszene_vorlaeufig.jpg"
                    }
                    alt="..."
                    borderRadius={0}
                    maxWidth={1}
                    height={400}
                    sx={{
                      objectFit: "cover",
                    }}
                  />,
                  <Box
                    component={"img"}
                    loading="lazy"
                    height={1}
                    width={1}
                    src={
                      "https://firebasestorage.googleapis.com/v0/b/yellow-web-6310c.appspot.com/o/cbs4.png?alt=media&token=db9cb1dd-fa84-43f3-977b-515e9bd7b148"
                    }
                    alt="..."
                    borderRadius={0}
                    maxWidth={1}
                    height={470}
                    sx={{
                      objectFit: "cover",
                    }}
                  />,
                  <Box
                    component={"img"}
                    loading="lazy"
                    height={1}
                    width={1}
                    src={
                      "https://firebasestorage.googleapis.com/v0/b/yellow-web-6310c.appspot.com/o/cbs5.png?alt=media&token=8f175852-fcdf-4aa4-bda6-c7b776d5886d"
                    }
                    alt="..."
                    borderRadius={0}
                    maxWidth={1}
                    height={470}
                    sx={{
                      objectFit: "cover",
                    }}
                  />,

                  <Box
                    component={"img"}
                    loading="lazy"
                    height={1}
                    width={1}
                    src={
                      "https://firebasestorage.googleapis.com/v0/b/yellow-web-6310c.appspot.com/o/cb_pdf_mock.jpg?alt=media&token=5486f1bd-87e3-49d4-95f7-a8ec812f186b"
                    }
                    alt="..."
                    borderRadius={0}
                    maxWidth={1}
                    height={400}
                    sx={{
                      objectFit: "cover",
                    }}
                  />,
                ].map((item, i) => item)}
              </Carousel>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant={"h6"} fontWeight={700} gutterBottom>
                Ziel
              </Typography>
              <Typography component={"p"}>
                Echtes Kaufinteresse wird schnell identifiziert und Zeitfresser
                eliminiert. Zudem finden Kunden zielsicher und
                unmissverständlich heraus, welches das richtige Produkt für Sie
                ist.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant={"h6"} fontWeight={700} gutterBottom>
                Lösung
              </Typography>
              <Typography component={"p"}>
                Wir haben in 3 Monaten einen 3D-Konfigurator auf die Beine
                gestellt, der die wichtigsten Produkt-Varianten demonstriert und
                sowohl im Beratungsgespräch als auch eigenständig vom Kunden
                genutzt werden kann.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <center>
                <a href={"https://3d.cube-fx.de"} target={"_blank"}>
                  <Button
                    sx={{
                      "&:hover": { background: "#222222" },
                      background: "#222222",
                      color: "white",
                      width: "230px",
                      borderRadius: "22px",
                    }}
                  >
                    Zum Konfigurator
                  </Button>
                </a>
              </center>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={{ xs: "flex-start", md: "space-between" }}
            height={1}
          >
            <Box>
              <Typography variant={"h6"} fontWeight={700} gutterBottom>
                Problem
              </Typography>
              <Typography

              // color={
              //   theme.palette.mode === 'light'
              //     ? 'text.secondary'
              //     : 'text.primary'
              // }
              >
                Sehr zeitaufwändiger Beratungsprozess, weil das Produkt viele
                Ausstattungsoptionen hat. Häufig stellt sich nach stundenlangen
                Beratungsgesprächen heraus, dass kein echtes Kaufinteresse oder
                Budget vorhanden ist. Sales-Cycles gehen häufig über Monate.
              </Typography>
            </Box>
            <Box
              component={Card}
              marginTop={{ xs: 2, md: 2 }}
              boxShadow={0}
              borderRadius={0}
              sx={{background: "white"}}
            >
              <CardContent sx={{ padding: { xs: 2, sm: 4 } }}>
                <center>
                  <Avatar
                    src={
                      "https://firebasestorage.googleapis.com/v0/b/yellow-web-6310c.appspot.com/o/christina-probst.256x256.jpg?alt=media&token=e7260589-836f-47e5-adab-1b05fd533081"
                    }
                    sx={{ marginRight: 1, height: "140px", width: "140px" }}
                  />
                </center>
                <br />
                <Typography component={"p"}>
                  Die Zusammenarbeit war hervorragend. Martin und Johannes sind
                  Profis und haben sichergestellt, dass unser Konfigurator ein
                  Erfolg wird. Mit dem Konfigurator sparen wir sehr viel Zeit
                  und können unsere Kunden optimal beraten.
                </Typography>
                <Box marginTop={{ xs: 2, sm: 4 }}>
                  <Typography variant={"subtitle1"} sx={{ fontWeight: 700 }}>
                    Christina Probst
                  </Typography>
                  <Typography color="text.secondary">
                    Sales Managerin
                  </Typography>
                </Box>
                <br />
                <Box
                  component="img"
                  height={1}
                  width={1}
                  src={
                    "https://cube-fx.de/wp-content/uploads/2022/12/CUBEfx_Logo_new.png"
                  }
                  alt="..."
                  maxWidth={80}
                  marginBottom={2}
                  sx={{
                    filter:
                      theme.palette.mode === "dark"
                        ? "brightness(0) invert(0.7)"
                        : "none",
                  }}
                />
              </CardContent>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CaseStudy1;
