import React from 'react';

function ArrowsSameDirection(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const strokewidth = props.strokewidth || 1;
	const width = props.width || '100%';
	const height = props.height || '100%';
	const title = props.title || "arrows same direction";

	return (
		<svg height={height} width={width} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
	<title>{title}</title>
	<g>
		<path d="M3,12h7v5a1,1,0,0,0,.561.9A.983.983,0,0,0,11,18a1,1,0,0,0,.614-.211l9-7a1,1,0,0,0,0-1.578l-9-7A1,1,0,0,0,10,3V8H3a2,2,0,0,0,0,4Z" fill="#c4c4c4"/>
		<path d="M37.614,16.211A1,1,0,0,0,36,17v5H3a2,2,0,0,0,0,4H36v5a1,1,0,0,0,.561.9A.983.983,0,0,0,37,32a1,1,0,0,0,.614-.211l9-7a1,1,0,0,0,0-1.578Z" fill="#ffcb00"/>
		<path d="M25.614,30.211A1,1,0,0,0,24,31v5H3a2,2,0,0,0,0,4H24v5a1,1,0,0,0,.561.9A.983.983,0,0,0,25,46a1,1,0,0,0,.614-.211l9-7a1,1,0,0,0,0-1.578Z" fill="#d6d6d6"/>
	</g>
</svg>
	);
};

export default ArrowsSameDirection;