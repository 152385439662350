import React from 'react';

function Magnet(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const strokewidth = props.strokewidth || 1;
	const width = props.width || '100%';
	const height = props.height || '100%';
	const title = props.title || "magnet";

	return (
		<svg height={height} width={width} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
	<title>{title}</title>
	<g>
		<path d="M12.275,28.728c0,1.872,0.727,3.63,2.047,4.95s3.078,2.047,4.95,2.047s3.629-0.727,4.949-2.047l8.228-8.228 L22.55,15.55l-8.228,8.228C13.002,25.099,12.275,26.855,12.275,28.728z" fill="none"/>
		<path d="M24.222,33.678c-1.32,1.32-3.077,2.047-4.949,2.047s-3.63-0.727-4.95-2.047s-2.047-3.078-2.047-4.95 s0.727-3.629,2.047-4.949l8.634-8.634l-6.946-7.196l-8.76,8.759c-6.628,6.628-6.628,17.414,0,24.042 c3.314,3.313,7.668,4.971,12.021,4.971c4.354,0,8.707-1.657,12.021-4.971l8.603-8.603l-7.04-7.102L24.222,33.678z" fill="#e4e4e4"/>
		<path d="M28.465,9.636c0.188-0.188,0.293-0.441,0.293-0.707s-0.105-0.52-0.293-0.707l-5.657-5.656 c-0.391-0.391-1.023-0.391-1.414,0l-5.914,5.914l7.071,7.071L28.465,9.636z" fill="#ffcb00"/>
		<path d="M45.435,25.192l-5.656-5.657c-0.188-0.188-0.441-0.293-0.707-0.293s-0.52,0.105-0.707,0.293L32.45,25.45 l7.071,7.071l5.914-5.914C45.825,26.216,45.825,25.583,45.435,25.192z" fill="#ffcb00"/>
	</g>
</svg>
	);
};

export default Magnet;