import React from 'react';

function CartSpeed(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const strokewidth = props.strokewidth || 1;
	const width = props.width || '100%';
	const height = props.height || '100%';
	const title = props.title || "cart speed";

	return (
		<svg height={height} width={width} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
	<title>{title}</title>
	<g fill={secondaryfill} stroke={secondaryfill} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10" strokeWidth={strokewidth}>
		<polyline fill="none" points="2,31 41,31 45,10 11,10 10,3 4,3 " stroke={fill}/>
		<circle cx="15" cy="41" fill="none" r="4"/>
		<circle cx="39" cy="41" fill="none" r="4"/>
		<line fill="none" x1="23" x2="10" y1="17" y2="17"/>
		<line fill="none" x1="19" x2="6" y1="24" y2="24"/>
	</g>
</svg>
	);
};

export default CartSpeed;