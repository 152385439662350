import { useEffect, Suspense, React } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Routes from './Routes';
import Page from './components/Page';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'aos/dist/aos.css';



const App = () => {
  useEffect(()=>{
    if(!document.cookie.match(/^(.*;)?\s*referrer\s*=\s*[^;]+(.*)?$/)){

      // document.cookie = "referrer=" +"123"+"domain=example.com"
      document.cookie = "referrer=" + document.referrer +";max-age=2147483647;";
    }
    },[])

  return (
    
      // <Suspense fallback={ <div
      //   id="logo-loading"
       
      //   style={{
      //     width: "100vw",
      //     height: "100vh",
      //     position: "fixed",
      //     display: "flex",
      //     left: 0,
      //     top: 0,
      //   }}
      // >
      //   <img
      //     id="lgo"
      //     width="130px"
      //     style={{ margin: "auto" }}
      //     src="/y_logo.png"
      //   />
      // </div>}>
    <Page>
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </Page>
    // </Suspense>
  );
};

export default App;
