import * as React from "react";
import Avatar from "@mui/joy/Avatar";
import FormLabel from "@mui/joy/FormLabel";
import Radio, { radioClasses } from "@mui/joy/Radio";
import RadioGroup from "@mui/joy/RadioGroup";
import Sheet from "@mui/joy/Sheet";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { Box, Grid, Modal } from "@mui/material";
import { PrimaryColor, primaryColor } from "Routes";
import InfoIcon from "@mui/icons-material/Info";
import FadeInModal from "FadeInModal";
import { useState } from "react";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import IconButton from "@mui/joy/IconButton";
import Menu from "@mui/joy/Menu";
import MenuItem from "@mui/joy/MenuItem";
import Apps from "@mui/icons-material/Apps";
import { PropaneSharp } from "@mui/icons-material";
import CurrencyFormat from "react-currency-format";
import { ModalDialog, Slider, Tooltip } from "@mui/joy";
import { Transition } from "react-transition-group";
import CloseIcon from "@mui/icons-material/Close";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { geocodeByPlaceId } from "react-google-places-autocomplete";
import { DistanceMatrixService, useJsApiLoader } from "@react-google-maps/api";
import CountUp from "react-countup";
import { doc, setDoc } from "firebase/firestore";
import { db } from "index";
import { useParams } from "react-router-dom";
import useWindowSize from "useWindowSize";

export function AppsMenu() {
  const buttonRef = React.useRef(null);
  const [open, setOpen] = React.useState(false);
  const innerWidth = useWindowSize();
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <IconButton
        ref={buttonRef}
        id="apps-menu-demo"
        aria-label="Applications"
        aria-controls={"apps-menu"}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="plain"
        color="neutral"
        onClick={() => {
          setOpen(!open);
        }}
        sx={{ borderRadius: 40 }}
      >
        <Apps />
      </IconButton>
      <Menu
        id="apps-menu"
        variant="solid"
        invertedColors
        anchorEl={buttonRef.current}
        open={open}
        onClose={handleClose}
        aria-labelledby="apps-menu-demo"
        sx={{
          "--List-padding": "0.5rem",
          "--ListItemDecorator-size": "3rem",
          display: "grid",
          gridTemplateColumns: "repeat(3, 100px)",
          gridAutoRows: "100px",
          gap: 1,
        }}
      >
        <MenuItem orientation="vertical" onClick={handleClose}>
          <ListItemDecorator>
            <Avatar>S</Avatar>
          </ListItemDecorator>
          Search
        </MenuItem>
        <MenuItem orientation="vertical" onClick={handleClose}>
          <ListItemDecorator>
            <Avatar>M</Avatar>
          </ListItemDecorator>
          Maps
        </MenuItem>
        <MenuItem orientation="vertical" onClick={handleClose}>
          <ListItemDecorator>
            <Avatar>M</Avatar>
          </ListItemDecorator>
          Mail
        </MenuItem>
        <MenuItem orientation="vertical" onClick={handleClose}>
          <ListItemDecorator>
            <Avatar>D</Avatar>
          </ListItemDecorator>
          Drive
        </MenuItem>
        <MenuItem orientation="vertical" onClick={handleClose}>
          <ListItemDecorator>
            <Avatar>C</Avatar>
          </ListItemDecorator>
          Calendar
        </MenuItem>
      </Menu>
    </Box>
  );
}

export default function ConfiguratorRangeField({
  options,
  setCurrentValue,
  currentValue,
  largeMenu,
  field,
  currentMenu,
  submitted,
  price,
  configuration,
  setConfiguration,
  setDeliveryCosts,
  deliveryDestination,
  setDeliveryDestination,
}) {
  const [infoModalText, setInfoModalText] = useState(null);
  const [infoModalOpen, setInfoModalOpen] = useState(false);
  const [deliveryModalOpen, setDeliverModalOpen] = useState(false);
  const [adress, setAdress] = useState(null);
  // const [distance, setDistance] = useState(0);
  // const [destination, setDestination] = useState(
  //   deliveryDestination
  //     ? deliveryDestination
  //     : { lat: 50.1392953, lng: 8.144082 }
  // );
  const innerWidth = useWindowSize();

  // const { isLoaded } = useJsApiLoader({
  //   id: 'google-map-script',
  //   googleMapsApiKey: "AIzaSyBTmxMWFGNRQ5QtJ2XnPBlFxxSl368w6Gg"
  // })

  // React.useEffect(() => {
  //   setDestination(deliveryDestination);
  //   if (deliveryDestination) {
  //     try {
  //       let geocoder = new window.google.maps.Geocoder();
  //       let adress;
  //       geocoder.geocode(
  //         { location: deliveryDestination },
  //         function (results, status) {
  //           if (status === window.google.maps.GeocoderStatus.OK) {
  //             if (results[1]) {
  //               console.log("ADRESS", results)
  //               setAdress(results[1].formatted_address)
  //             }
  //           }
  //         }
  //       )
  //     } catch (e) {
  //       console.log(e, "Error retrieving adress");
  //     }
  //   }
  // }, [deliveryDestination]);

  // React.useEffect(() => {
  //   let v = field.options.filter((i) => i.title == currentValue)[0];
  //   console.log("optsss", field.options, v);
  //   if (v && v.deliveryModal) {
  //     setDeliveryCosts(distance.toFixed(0) * 2);
  //   } else {
  //     setDeliveryCosts(0);
  //     setCurrentValue(currentValue);
  //   }
  // }, [distance, currentValue]);

  // React.useEffect(() => {
  //   console.log("adresse ist", adress, configuration);
  // }, [adress]);

  let { configurationId } = useParams();
  let pc = PrimaryColor();
  const isValid = (value) => {
    return true;
  };
  return (
    <>
      <Box
        className={"select-options-container"}
        sx={{
          // paddingLeft:
          //   options.length > 2 && innerWidth < 800
          //     ? "calc(50vw - 157px)"
          //     : "0px",
          height:
            field.inputType == "layeredSelect"
              ? "55px"
              : window.WURFL.is_mobile &&
                window.WURFL.form_factor === "Smartphone"
              ? "130px"
              : "105px",
          // display: "flex",
          // margin: "auto",
          flexDirection: "horizontal",
          textAlign: "center",
          overflowX: innerWidth > 800 ? "auto" : "auto",
          overflowY: "hidden",
          whiteSpace: "nowrap",
          //scrollbarWidth: "none",
          paddingTop: "4px",
          "::-webkit-scrollbar":
            window.WURFL.is_mobile && window.WURFL.form_factor === "Smartphone"
              ? { display: "none" }
              : null,

          marginTop:
            field.inputType == "layeredSelect" && innerWidth < 800
              ? "-3px"
              : null,
          marginBottom:
            field.inputType == "layeredSelect" && !field.colors
              ? "5px"
              : field.inputType == "layeredSelect"
              ? "7px"
              : 0,
          // width: (innerWidth > 800 ? "auto" : "250px")
        }}
      >
        <FadeInModal
          childrenHtml={infoModalText}
          open={infoModalOpen}
          setOpen={setInfoModalOpen}
        />
        <Box
          sx={{
            margin: "auto",
            overflow: "hidden",
            display: innerWidth < 800 && largeMenu ? "inline" : "inline",
          }}
        >
          {/* <AppsMenu/> */}
          <Box
            sx={{
              display: innerWidth > 800 ? "inline-block" : "inline-block",
            }}
          >
            <div
              style={{
                height: "0px",
                width: "100%",
                marginBottom: "-8px",
                marginLeft: "-8px",
                textAlign: "right",
                zIndex: 100,
              }}
            ></div>
          </Box>

          <Slider
            sx={{
              width: Math.min(innerWidth, 500) + "px",
              zIndex: 10000000,
              height: "100px",
            }}
            aria-label="Custom marks"
            defaultValue={currentValue}
            //getAriaValueText={valuetext}
            step={10}
            min={235}
            max={650}
            valueLabelFormat={(v) => v + " cm"}
            onChangeCommitted={(e, v) => {
              setCurrentValue(v);
              console.log(v);
            }}
            valueLabelDisplay="auto"
            //marks={marks}
          />
        </Box>
      </Box>
    </>
  );
}
