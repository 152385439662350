import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { alpha, useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';

import { NavItem } from './components';
import { useScrollTrigger } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Slide from '@mui/material/Slide';
import { ProgressBar } from '@nadfri/react-scroll-progress-bar';
// import { GoogleTranslate } from 'views/CloudHosting/CloudHosting';

const Topbar = ({ onSidebarOpen, pages, colorInvert = false }) => {
  const theme = useTheme();
  const trigger = useScrollTrigger({
    // disableHysteresis: true,
    // threshold: 38,
     target: window
    
  });
  const navigate = useNavigate()
  const { mode } = theme.palette;
  const {
    landings: landingPages,
    secondary: secondaryPages,
    company: companyPages,
    account: accountPages,
    portfolio: portfolioPages,
    blog: blogPages,
  } = pages;

  return (
   
    <Box
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'center'}
      width={1}
     sx={{height: "60px",paddingTop: {xs: 0, md: "13px"}, paddingBottom: {xs:0, md: "13px"}}}
     
    >
      <Box
        display={'flex'}
       
        component="a"
        href="/"
        title="Configora"
        //width={{ xs: 120, md: 120 }}
      >
        <Box
          component={'img'}
          // sx={{"&:hover": {transition: "filter .5s ease-in-out",filter: "drop-shadow(1px 1px 1px black)"}}}
        height={"35px"}
        width={"auto"}
        //  width={1}
          src={
            mode === 'light' && !colorInvert
              // ? '/logo.svg'
              ? '/c_logo.png'
              : 'https://assets.maccarianagency.com/the-front/logos/logo-negative.svg'
          }
        />
      </Box>
      <Box sx={{ display: { xs: 'block', md: 'flex' }, visibility: { xs: 'hidden', md: 'visible' }}} alignItems={'center'} >
        {/* <Box>
          <NavItem
            title={'Landings'}
            id={'landing-pages'}
             items={landingPages}
            colorInvert={colorInvert}
          />
        </Box>
        <Box marginLeft={4}>
          <NavItem
            title={'Company'}
            id={'company-pages'}
            items={companyPages}
            colorInvert={colorInvert}
          />
        </Box>
        <Box marginLeft={4}>
          <NavItem
            title={'Account'}
            id={'account-pages'}
            items={accountPages}
            colorInvert={colorInvert}
          />
        </Box>
        <Box marginLeft={4}>
          <NavItem
            title={'Pages'}
            id={'secondary-pages'}
            items={secondaryPages}
            colorInvert={colorInvert}
          />
        </Box>
        <Box marginLeft={4}>
          <NavItem
            title={'Blog'}
            id={'blog-pages'}
            items={blogPages}
            colorInvert={colorInvert}
          />
        </Box> */}
         <Box sx={{"&:hover":{textDecoration: "underline", color: "black", cursor: "pointer"}}} marginLeft={4} onClick={()=>navigate("/")}>
          <NavItem
            title={'Start'}
            id={'portfolio-pages'}
            // items={portfolioPages}
            colorInvert={colorInvert}
            
          />
        </Box>
         <Box sx={{"&:hover":{textDecoration: "underline", color: "black", cursor: "pointer"}}} marginLeft={4} onClick={()=>navigate("/warum-3d")}>
          <NavItem
            title={'3D Experiences'}
            id={'portfolio-pages'}
            // items={portfolioPages}
            colorInvert={colorInvert}
            
          />
        </Box>
         {/* <Box sx={{"&:hover":{textDecoration: "underline", color: "black", cursor: "pointer"}}} marginLeft={4} onClick={()=>window.open("https://ynflow.tawk.help/", "_blank")}>
          <NavItem
            title={'Dokumentation'}
            
           
            id={'portfolio-pages'}
            // items={portfolioPages}
            colorInvert={colorInvert}
          />
        </Box> */}
        <Box sx={{"&:hover":{textDecoration: "underline", color: "black", cursor: "pointer"}}} marginLeft={4} onClick={()=>navigate("/login")}>
          <NavItem
            title={'Login'}
            
           
            id={'portfolio-pages'}
            
            // items={portfolioPages}
            colorInvert={colorInvert}
          />
        </Box>
        {/* <Box sx={{"&:hover":{textDecoration: "underline", color: "black", cursor: "pointer"}}} marginLeft={4} >
        <div id="google_translate_element" style={{zIndex: 5000, position: "relative", display: "inline"}}></div>
       

       <GoogleTranslate/>
        </Box> */}
       
        <Box marginLeft={4} >
          <Button
            variant="contained"
            color="primary"
            component="a"
            // target="blank"
            href="#request-demo"
            className={"butn butn__new"}
            size="large"
            onClick={()=>window.open("https://calendly.com/configora/configora-demo", "_blank")}
            sx={{   "&:hover": { background: "#ffcb00" }, borderRadius: "28px",
            fontWeight: "500",boxShadow: `rgba(0, 0, 0, 0.15) 0px 2px 8px`,transition: "background .3s linear", color: "black", background: (trigger?  theme.palette.primary.main: theme.palette.primary.main)}}
            endIcon={
              <Box
                component={'svg'}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                width={24}
                height={24}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M17 8l4 4m0 0l-4 4m4-4H3"
                />
              </Box>
            }
          >
            Kostenlose Demo
          </Button>
        </Box>
      </Box>
      <Box sx={{ display: { xs: 'block', md: 'none' } }} alignItems={'center'}>
        <Button
          onClick={() => onSidebarOpen()}
          aria-label="Menu"
          variant={'outlined'}
          sx={{
            borderRadius: 2,
            minWidth: 'auto',
            padding: 1,
            border: "1px solid",
            transition: "all .3s linear",
            color: theme.palette.primary.main,
            borderColor:theme.palette.primary.main,
          }}
        >
        
          <MenuIcon />
        </Button>
      </Box>
      {/* <ProgressBar 
        color1   = "#fff" 
        color2   = "#ffcb00" 
        height   = "2px"
        position = "fixed" 
        /> */}
    </Box>
   
  );
};

Topbar.propTypes = {
  onSidebarOpen: PropTypes.func,
  pages: PropTypes.object,
  colorInvert: PropTypes.bool,
};

export default Topbar;
