/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

import Container from "components/Container";
import { useTheme } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import {signInWithEmailAndPassword} from "firebase/auth"
import { auth } from "index";
import { useAuthState } from "react-firebase-hooks/auth";
import useWindowSize from "useWindowSize";

const SimpleSignInForm = () => {
  const theme = useTheme();
  const [email, setEmail] = useState(null);
  const [user, loading, error] = useAuthState(auth);
  const [password, setPassword] = useState(null);
  const navigate = useNavigate();
  const innerWidth = useWindowSize();
  useEffect(()=>{
    user && navigate("/portal/sales")
  },[user])
  return (
    <Box
      bgcolor={"white"}
      sx={{ height: "100vh", display: "flex", position: "relative" }}
    >
      <Box
        onClick={() => navigate("/")}
        component={"div"}
        sx={{
          cursor: "pointer",
          position: "absolute",
          top: (innerWidth > 800 ? "20px": null),
          bottom: (innerWidth > 800 ? null: "20px"),
          left: "20px",
          width: "100px",
          textAlign: "center",
        }}
      >
        <ArrowBackIcon
          sx={{ fontSize:  "1.3rem",  marginBottom: "-5px", marginRight: "7px", color: "#666" }}
        />
       
        <span style={{ color: "#666" }}>BACK</span>
      </Box>
      <Container maxWidth={800} sx={{ margin: "auto" }}
       onKeyDown={(event) => {
        if (event.keyCode === 13 || event.keyCode == 9) {
          signInWithEmailAndPassword(auth, email, password);
        }
      }}>
        <Box marginBottom={4}>
          <Typography
            sx={{
              textTransform: "uppercase",
            }}
            gutterBottom
            color={theme.palette.primary.main}
            fontWeight={700}
          >
            Login
          </Typography>
          <Typography
            variant="h4"
            sx={{
              fontWeight: 700,
            }}
          >
            Welcome back
          </Typography>
          <Typography color="text.secondary">
            Login to manage your account.
          </Typography>
        </Box>
        <Card sx={{ p: { xs: 4, md: 6 }, marginBottom: "100px" }}>
          <form>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Typography variant={"subtitle2"} sx={{ marginBottom: 2 }}>
                  Enter your email
                </Typography>
                <TextField
                  label="Email *"
                  variant="outlined"
                  name={"email"}
                  fullWidth
                  onChange={(e)=>setEmail(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <Box
                  display="flex"
                  flexDirection={{ xs: "column", sm: "row" }}
                  alignItems={{ xs: "stretched", sm: "center" }}
                  justifyContent={"space-between"}
                  width={1}
                  marginBottom={2}
                >
                  <Box marginBottom={{ xs: 1, sm: 0 }}>
                    <Typography variant={"subtitle2"}>
                      Enter your password
                    </Typography>
                  </Box>
                  <Typography variant={"subtitle2"}>
                    <Link
                      component={"a"}
                      color={"primary"}
                      href={"#"}
                      underline={"none"}
                    >
                      Forgot your password?
                    </Link>
                  </Typography>
                </Box>
                <TextField
                  label="Password *"
                  variant="outlined"
                  name={"password"}
                  type={"password"}
                  fullWidth
                  onChange={(e)=>setPassword(e.target.value)}
                />
              </Grid>
              <Grid item container xs={12}>
                <Box
                  display="flex"
                  flexDirection={{ xs: "column", sm: "row" }}
                  alignItems={{ xs: "stretched", sm: "center" }}
                  justifyContent={"space-between"}
                  width={1}
                  maxWidth={600}
                  margin={"0 auto"}
                >
                  <Box marginBottom={{ xs: 1, sm: 0 }}>
                    <Typography variant={"subtitle2"}>
                      Don't have an account yet?{" "}
                      <Link
                        component={"a"}
                        color={"primary"}
                        href={"#"}
                        underline={"none"}
                      >
                        Start with a free demo now!
                      </Link>
                    </Typography>
                  </Box>
                  <Button
                    element={"a"}
                    // href={"/portal/sales"}
                    size={"large"}
                    variant={"contained"}
                    // type={"submit"}
                    onClick={()=>{
                      signInWithEmailAndPassword(auth, email,password)
                    }}
                  >
                    Login
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        </Card>
      </Container>
    </Box>
  );
};

export default SimpleSignInForm;
