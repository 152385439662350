import React from 'react';

function CQuestion(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const strokewidth = props.strokewidth || 1;
	const width = props.width || '100%';
	const height = props.height || '100%';

	return (
		<svg height={height} width={width} viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
	<g fill={secondaryfill} stroke={secondaryfill} strokeLinecap="round" strokeLinejoin="round" strokeWidth={strokewidth}>
		<circle cx="32" cy="32" fill="none" r="29" stroke={fill}/>
		<circle cx="32" cy="47" fill="none" r="1"/>
		<path d="M25,16.551c5.481-2.425,12.575-2.136,15,1.787s.748,8.483-3.4,12S32,36.232,32,39" fill="none"/>
	</g>
</svg>
	);
};

export default CQuestion;