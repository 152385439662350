import React from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";

const ARView = (props) => {
  let {configurationId} = useParams();
  return (
    <>
      <Helmet>
        <script
          type="module"
          src="https://modelviewer.dev/node_modules/@google/model-viewer/dist/model-viewer.min.js"
        ></script>
      </Helmet>
      <model-viewer
        //ios-src="some_test.usdz"
        xr-environment
        //environment-image={"/white_bg.jpg"}
        ar-modes="webxr scene-viewer quick-look"
        ar
        alt="Neil Armstrong's Spacesuit from the Smithsonian Digitization Programs Office and National Air and Space Museum"
        src={props.glbDownloadUrl? props.glbDownloadUrl: "https://firebasestorage.googleapis.com/v0/b/yellow-web-6310c.appspot.com/o/" + configurationId +  ".glb?alt=media&token=1b5f0f98-2bcf-4681-a185-f3ea042f13bb"}
        tone-mapping="aces_filmic"
        shadow-intensity="0"
        scale="0.2 0.2 0.2"
        camera-controls
        blend-mode="default"
        //environment-image="/landscape_sm.hdr"
        touch-action="pan-y"
        exposure={"1"}
        
      >
        <button slot="ar-button" id="ar-button">
          Start AR
        </button>
        <button id="ar-failure">AR is not tracking!</button>

        <div id="ar-prompt">
          <img src="https://modelviewer.dev/shared-assets/icons/hand.png" />
        </div>
        <effect-composer render-mode="quality" msaa="8">
    <bloom-effect></bloom-effect>
    <color-grade-effect></color-grade-effect>
  </effect-composer>
      </model-viewer>
    </>
  );
};

export default ARView;
