import * as React from "react";
import Avatar from "@mui/joy/Avatar";

import { Box, Grid, Tooltip, Typography } from "@mui/material";
import { PrimaryColor, primaryColor } from "Routes";
import InfoIcon from "@mui/icons-material/Info";
import FadeInModal from "FadeInModal";
import { useState } from "react";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import IconButton from "@mui/joy/IconButton";
import Menu from "@mui/joy/Menu";
import MenuItem from "@mui/joy/MenuItem";
import Apps from "@mui/icons-material/Apps";
import {
  AddCircleOutline,
  LensOutlined,
  RemoveCircleOutline,
  RemoveCircleOutlined,
} from "@mui/icons-material";
import useWindowSize from "useWindowSize";
const isValid = (value) => {
  return true;
};
export default function ConfiguratorMultiSelectField({
  options,
  setCurrentValue,
  currentValue,
  largeMenu,
  field,
  currentMenu,
  configuration,
  submitted,
}) {
  const [infoModalText, setInfoModalText] = useState(null);
  const [infoModalOpen, setInfoModalOpen] = useState(false);
  let pc = PrimaryColor();
  const innerWidth = useWindowSize();
  return (
    <Box
      sx={{
        whiteSpace: "nowrap",
        overflowY: "scroll",
        "::-webkit-scrollbar": { display: "none" },
      }}
    >
      <Box
        className={"select-options-container"}
        sx={{
          // paddingLeft: options.length  > 2 && innerWidth <800 ? "calc(50vw - 87px)" : "0px",

          height: "115px",
          display: "flex",
          // margin: "auto",
          flexDirection: "horizontal",
          textAlign: "center",

          overflowX: innerWidth > 800 ? "scroll" : "scroll",
          overflowY: "visible",
          width: field.cards? "auto" : options
            ? options.length *
                (options.length < 6 ? 100 : field.amounts ? 140 : 120) +
              "px"
            : "800px",
          margin: "auto",
          scrollbarWidth: "none",
          whiteSpace: field.cards? "no-wrap":"normal",

          // width: (innerWidth > 800 ? "auto" : "250px")
        }}
      >
        <FadeInModal
          childrenHtml={infoModalText}
          open={infoModalOpen}
          setOpen={setInfoModalOpen}
        />

        <Box
          sx={{
            margin: "auto",
            overflow: "visible",
            whiteSpace: field.cards? "no-wrap":"normal",
            display: innerWidth < 800 && largeMenu ? "inline" : "inline",
          }}
        >
          {/* <AppsMenu/> */}
          <Box
            sx={{
              display: innerWidth > 800 ? "inline-block" : "inline-block",
            }}
          >
            <div
              style={{
                height: "0px",
                width: "100%",
                marginBottom: "-8px",
                marginLeft: "-8px",
                textAlign: "right",
                zIndex: 100,
              }}
            ></div>
          </Box>

          {!field.cards
            ? options.map((value) => (
                <Box
                  sx={{
                    display: innerWidth > 800 ? "inline-block" : "inline-block",
                  }}
                >
                  <div
                    style={{
                      height: "0px",
                      width: "100%",
                      marginBottom: "-8px",
                      marginLeft: "-8px",
                      textAlign: "right",
                      zIndex: 100,
                    }}
                  >
                    {/* {currentValue == value.title &&
                      field.inputType == "multiSelect" && (
                        <span
                          style={{
                            background: "white",
                            minWidth: "27px",
                            display: "inline-block",
                          }}
                        >
                          <svg
                            width={"24px"}
                            fill={pc && pc.primaryColor}
                            stroke="white"
                            class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                            focusable="false"
                            aria-hidden="true"
                            viewBox="0 0 24 24"
                            data-testid="CheckCircleRoundedIcon"
                          >
                            <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM9.29 16.29 5.7 12.7a.9959.9959 0 0 1 0-1.41c.39-.39 1.02-.39 1.41 0L10 14.17l6.88-6.88c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41l-7.59 7.59c-.38.39-1.02.39-1.41 0z"></path>
                          </svg>
                        </span>
                      )} */}
                  </div>
                  <Box
                    variant="outlined"
                    onClick={() => {
                      if (field.inputType == "multiSelect") {
                        if (!currentValue.includes(value.title)) {
                          setCurrentValue([...currentValue, value.title]);
                        } else {
                          let v = [...currentValue];
                          v.splice(currentValue.indexOf(value.title), 1);
                          setCurrentValue(v);
                        }
                      }
                    }}
                    sx={{
                      border: currentValue.includes(value.title)
                        ? "2px solid " + (pc && pc.primaryColor)
                        : "2px solid white",

                      borderRadius: "30px",
                      bgcolor: "background.body",
                      boxShadow: "rgba(0, 0, 0, 0.15) 0px 2px 8px",
                      background: "white",
                      cursor: "pointer",
                      color:
                        // currentValue.includes(value.title)
                        //   ? pc && pc.primaryColor
                        //   :
                        "black",
                      fontWeight: currentValue.includes(value.title)
                        ? "bold"
                        : "normal",
                      marginTop: "-30px",

                      padding: "0px 13px 0 13px",
                      display: "inline-block",
                      minWidth: 100,
                      whiteSpace: "normal",
                      height: "35px",
                      maxHeight: "150px",
                      display: !value.image ? "flex" : "flex",

                      m: 1,
                      marginTop:
                        currentValue &&
                        currentValue[0] == value.title &&
                        currentValue[1] == value.subtitle
                          ? "8px"
                          : "9px",
                      marginLeft:
                        currentValue &&
                        currentValue[0] == value.title &&
                        currentValue[1] == value.subtitle
                          ? "8px"
                          : "9px",
                      marginRight:
                        currentValue &&
                        currentValue[0] == value.title &&
                        currentValue[1] == value.subtitle
                          ? "8px"
                          : "9px",
                    }}
                  >
                    {value.image && (
                      <img
                        src={value.image}
                        width={"20px"}
                        height={"20px"}
                        style={{
                          width: "20px",
                          height: "20px",
                          display: "inline",
                          objectFit: "contain",
                          margin: "auto",
                          marginTop: "5px",
                          marginRight: "6px",
                        }}
                      />
                    )}

                    <Box sx={{ display: "flex", fontSize: "0.85rem" }}>
                      {field.amounts && (
                        <RemoveCircleOutline
                          sx={{
                            cursor: "pinter",
                            color: "#aaa",
                            marginTop: "5px",
                            marginRight: "7px",
                          }}
                        />
                      )}

                      <div
                        style={{
                          margin: value.subtitle ? "5px 0 5px 0px" : "auto",
                        }}
                      >
                        <div
                          style={{
                            marginTop: !value.image ? "-0px" : "-3px",
                            marginBottom: !value.image ? 0 : "1.5px",
                          }}
                        >
                          {value.verboseTitle
                            ? value.verboseTitle
                            : value.title}
                        </div>

                        {/* {value.info && (
                    <InfoIcon
                      sx={{
                        display: "inline",
                        "& :hover": { color: "black" },
                        color: "grey",
                        cursor: "pointer",
                        marginBottom: "-3px",
                        marginLeft: "2px",
                        fontSize: "0.9rem",
                      }}
                      onClick={() => {
                        setInfoModalText(value.info);
                        setInfoModalOpen(true);
                      }}
                    />
                  )} */}
                        {value.subtitle && (
                          <div
                            style={{
                              marginTop: "-11px",
                              marginBottom: "10px",
                              height: "6px",
                              display: "block",
                            }}
                          >
                            <small
                              style={{
                                color: "#666",
                                whiteSpace: "pre-wrap",
                                marginBottom: "-6px",
                              }}
                            >
                              {value.subtitle ? value.subtitle : " "}
                            </small>
                          </div>
                        )}
                      </div>
                      {field.amounts && (
                        <AddCircleOutline
                          sx={{
                            cursor: "pinter",
                            color: "#aaa",
                            marginTop: "5px",
                            marginLeft: "7px",
                          }}
                        />
                      )}
                    </Box>
                  </Box>
                </Box>
              ))
            : options.map((value) => {
                return (
                  <Box
                    sx={{
                      display:
                        innerWidth > 800 ? "inline-block" : "inline-block",
                    }}
                  >
                    <div
                      style={{
                        height: "0px",
                        width: "100%",
                        marginBottom: field.colors ? "-8px" : "0px",
                        marginTop: field.colors ? "0px" : "-10px",
                        marginLeft: "-8px",
                        textAlign: "right",
                        zIndex: 100,
                      }}
                    >
                      {currentValue.includes(value.title) &&
                        field.inputType == "multiSelect" ? (
                          <span
                            style={{
                              background: "transparent",
                              padding: "3px",
                              minWidth: "27px",
                              marginTop: "10px",
                              display: "inline-block",
                            }}
                          >
                            <svg
                              width={"20px"}
                              fill={pc && pc.primaryColor}
                              stroke="white"
                              class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                              focusable="false"
                              aria-hidden="true"
                              viewBox="0 0 24 24"
                              data-testid="CheckCircleRoundedIcon"
                            >
                              <path
                                fill={pc && pc.primaryColor}
                                d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM9.29 16.29 5.7 12.7a.9959.9959 0 0 1 0-1.41c.39-.39 1.02-.39 1.41 0L10 14.17l6.88-6.88c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41l-7.59 7.59c-.38.39-1.02.39-1.41 0z"
                              ></path>
                            </svg>
                          </span>
                        ): <span
                        style={{
                          background: "transparent",
                          padding: "3px",
                          minWidth: "27px",
                          marginTop: "6px",
                          display: "inline-block",
                        }}
                      >
                        <LensOutlined width={"17px"} style={{fontWeight: "10px", marginTop: "-1px", color: "#555"}}/>
                      </span>}
                    </div>
                    <Tooltip
                      sx={{
                        marginLeft: "-25px",
                        left: "-20px",
                        fontSize: "12px",
                        background: "rgba(80,80,80,0.92)",
                        display: innerWidth > 800 ? "auto" : "none",
                      }}
                      title={
                        currentValue &&
                        currentValue[2] &&
                        field.inputType == "layeredSelect" &&
                        value.prohibitedCombinations?.includes(
                          currentValue[2]
                        ) ? (
                          "EasyCUBE nur ohne Verkleidung"
                        ) : value.prohibitedCombinations &&
                          value.prohibitedCombinations.values &&
                          value.prohibitedCombinations.values.length > 0 &&
                          configuration.filter((obj) =>
                            value.prohibitedCombinations.values.includes(
                              obj.currentValue
                            )
                          ).length > 0 ? (
                          <span
                            dangerouslySetInnerHTML={{
                              __html: value.prohibitedCombinations.message,
                            }}
                          ></span>
                        ) : (
                          ""
                        )
                      }
                      placement={
                        field.inputType == "layeredSelect" ? "right" : "top"
                      }
                    >
                      <Box
                        variant="outlined"
                        onClick={() => {
                          if (field.inputType == "multiSelect") {
                            if (!currentValue.includes(value.title)) {
                              setCurrentValue([...currentValue, value.title]);
                            } else {
                              let v = [...currentValue];
                              v.splice(currentValue.indexOf(value.title), 1);
                              setCurrentValue(v);
                            }
                          }
                        }}
                        sx={{
                          border:
                            currentValue != null &&
                            (currentValue.includes(value.title) ||
                              !field.inputType == "layeredSelect" ||
                              currentValue[0] == value.title)
                              ? // &&currentValue[1] == value.subtitle
                                (currentMenu.branding?.selectBorderWidth
                                  ? currentMenu.branding.selectBorderWidth
                                  : "2px") +
                                " solid " +
                                (pc && pc.primaryColor)
                              : (currentMenu.branding?.selectBorderWidth
                                  ? currentMenu.branding.selectBorderWidth
                                  : "2px") +
                                " solid " +
                                (currentMenu.branding?.inactiveBorderColor
                                  ? currentMenu.branding.inactiveBorderColor
                                  : "8px"),

                          borderRadius:
                            field.inputType == "multiSelect"
                              ? currentMenu.branding?.borderRadius
                                ? currentMenu.branding.borderRadius
                                : "8px"
                              : "40px",
                          bgcolor: "background.body",
                          // border: "0.3px solid #ccc",
                          boxShadow: currentMenu.branding?.boxShadow
                            ? currentMenu.branding.boxShadow
                            : `rgba(0, 0, 0, 0.15) 0px 2px 8px`,
                          background:
                            (currentValue &&
                              currentValue[2] &&
                              field.inputType == "layeredSelect" &&
                              value.prohibitedCombinations?.includes(
                                currentValue[2]
                              )) ||
                            (value.prohibitedCombinations &&
                              value.prohibitedCombinations.values &&
                              value.prohibitedCombinations.values.length > 0 &&
                              configuration.filter((obj) =>
                                value.prohibitedCombinations.values.includes(
                                  obj.currentValue
                                )
                              ).length > 0)
                              ? "rgb(246,246,246)"
                              : "white",
                          pointerEvents: submitted ? "none" : "auto",
                          cursor:
                            (currentValue &&
                              currentValue[2] &&
                              field.inputType == "layeredSelect" &&
                              value.prohibitedCombinations?.includes(
                                currentValue[2]
                              )) ||
                            (value.prohibitedCombinations &&
                              value.prohibitedCombinations.values &&
                              value.prohibitedCombinations.values.length > 0 &&
                              configuration.filter((obj) =>
                                value.prohibitedCombinations.values.includes(
                                  obj.currentValue
                                )
                              ).length > 0)
                              ? "not-allowed"
                              : "pointer",
                          color:
                            (currentValue &&
                              currentValue[2] &&
                              field.inputType == "layeredSelect" &&
                              value.prohibitedCombinations?.includes(
                                currentValue[2]
                              )) ||
                            (value.prohibitedCombinations &&
                              value.prohibitedCombinations.values &&
                              value.prohibitedCombinations.values.length > 0 &&
                              configuration.filter((obj) =>
                                value.prohibitedCombinations.values.includes(
                                  obj.currentValue
                                )
                              ).length > 0)
                              ? "#888"
                              : "black",
                          marginTop: "-30px",
                          // transition: "border-color .3s linear",
                          padding:
                            field.inputType == "layeredSelect"
                              ? !(field.options[0].price >= 0)
                                ? "5px 16px"
                                : "2px 16px"
                              : "4px",

                          //   display: "inline-block",
                          minWidth: 100,

                          height: "auto",
                          maxHeight: "150px",
                          display: (!value.image && !field.cards) ? "flex" : "auto",

                          m: 1,
                          marginTop:
                            currentValue &&
                            currentValue[0] == value.title &&
                            currentValue[1] == value.subtitle
                              ? "8px"
                              : "9px",
                          marginLeft:
                            currentValue &&
                            currentValue[0] == value.title &&
                            currentValue[1] == value.subtitle
                              ? "8px"
                              : "9px",
                          marginRight:
                            currentValue &&
                            currentValue[0] == value.title &&
                            currentValue[1] == value.subtitle
                              ? "8px"
                              : "9px",
                        }}
                      >
                        {value.image ? (
                          <div style={{padding: "5px"}}>
                          <img
                            src={value.image}
                            width={"120px"}
                            height={innerWidth < 1200 ? "30px" : "35px"}
                            style={{
                              objectFit: "contain",
                              margin: "auto",
                              height: innerWidth < 1200 ? "30px" : "35px",
                            }}
                          />
                          </div>
                        ) : (
                          <div
                            style={{
                              position: "relative",
                              display: "block",
                              width: "120px",
                              margin: "auto",
                              height: innerWidth < 1200 ? "40px" : "45px",
                            }}
                          >{" "}</div>
                        )}
                        <Box
                          sx={{
                            margin: "auto",
                            display: "inline",
                            fontSize: "0.85rem",
                          }}
                        >
                          <span
                            style={{ lineHeight: "9px" }}
                            dangerouslySetInnerHTML={{
                              __html: value.verboseTitle
                                ? value.verboseTitle
                                : value.title,
                            }}
                          ></span>
                          {/* {value.info && (
                    <InfoIcon
                      sx={{
                        display: "inline",
                        "& :hover": { color: "black" },
                        color: "grey",
                        cursor: "pointer",
                        marginBottom: "-3px",
                        marginLeft: "2px",
                        fontSize: "0.9rem",
                      }}
                      onClick={() => {
                        setInfoModalText(value.info);
                        setInfoModalOpen(true);
                      }}
                    />
                  )} */}{" "}
                          {options.filter((opt) => opt.price).length > 0 && (
                            <>
                              <small
                                style={{
                                  color: "grey",
                                  whiteSpace: "pre-wrap",
                                  display: "block",
                                  marginTop: "-4px",
                                }}
                              >
                                {value.price || value.price == 0
                                  ? (!value.exactPrice ? "ab " : "") +
                                    value.price +
                                    " €"
                                  : " "}
                              </small>
                            </>
                          )}
                          {options.filter((opt) => opt.subtitle).length > 0 && (
                            <>
                              <small
                                style={{
                                  color: "grey",
                                  whiteSpace: "pre-wrap",
                                  display: "block",
                                  marginTop: "-4px",
                                }}
                              >
                                {value.subtitle ? value.subtitle : " "}
                              </small>
                            </>
                          )}
                        </Box>
                      </Box>
                    </Tooltip>
                  </Box>
                );
              })}
        </Box>
      </Box>
    </Box>
  );
}
